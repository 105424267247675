<template>
  <td v-if="GET_ROW_AVERAGE($route.params.sizeId, index)">
    {{ formattedValue }}
  </td>
  <td v-else/>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['name', 'index'],
  computed: {
    ...mapGetters('lubeAnalyser', ['GET_ROW_AVERAGE', 'GET_ROW_AVERAGES']),
    formattedValue () {
      let value = this.GET_ROW_AVERAGE(this.$route.params.sizeId, this.index)
      if (value) {
        if (this.name === 'Grease') return (value * 1000).toLocaleString() + ' kg'
        else if (this.name === 'total') return (value * 1000).toLocaleString() + ' units'
        else return value.toLocaleString() + ' kl'
      }
      return ''
    },
    getRowAverages () {
      return this.GET_ROW_AVERAGES(this.index)
    }
  }
}
</script>
