<template>
  <ion-col v-if="data" size-lg="4" size-sm="6" size="12" class="ion-margin-bottom">
    <ion-card color="secondary" @click="goToLink" class="dark-image ion-activatable">
      <div
        class="back-image"
        :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.5)), url(' + getImageUrl + ') center center / cover no-repeat', 'background-size': 'cover' }"
      />
      <ion-card-header>
        <ion-card-subtitle>PETRONAS</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-card-title class="h2">{{ data.name }}</ion-card-title>
        <ion-card-subtitle v-if="$route.name !== 'products.by_industry.industry-type'">{{ data.description }}</ion-card-subtitle>
        <ion-card-subtitle v-else>{{ GET_CATEGORY_BLOCK(data.cat).description }}</ion-card-subtitle>
      </ion-card-content>
      <ion-item lines="none">
        <ion-icon size="large" color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ["data"],
  computed: {
    ...mapGetters('products', [ 'GET_CATEGORY_BLOCK' ]),
    getImageUrl() {
      let newString = this.data.name.replace(/[^A-Z0-9]+/ig, '')
      return '/assets/images/products/' + newString + '.jpg'
    }
  },
  methods: {
    goToLink() {
      if (this.$route.name === 'products.by_product') {
        if (this.data.count > 1 || this.data.filtering) {
          this.$router.push({
            name: 'products.by_product.products-types',
            params: { 
              productTypeId: this.data.tid
            }
          })
        } else {
          this.$router.push({
            name: 'products.product',
            params: { 
              productId: this.data.products[0],
              from: this.$route
            }
          })
        }
      } else if (this.$route.name === 'products.by_industry') {
        this.$router.push({
          name: 'products.by_industry.industry-type',
          params: { 
            industryId: this.data.tid
          }
        })
      } else {
        if (this.data.count > 1 || this.data.filtering) {
          this.$router.push({
            name: 'products.by_industry.industry-type.product-types',
            params: { 
              productTypeId: this.data.cat,
              industryId: this.$route.params.industryId
            }
          })
        } else {
          this.$router.push({
            name: 'products.product',
            params: { 
              productId: this.data.products[0],
              from: this.$route
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
ion-row {
  ion-card {
    height: 100%;
    min-height: 220px;
    .cardfooter {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
.split-pane-ios.split-pane-visible>.split-pane-side,
.split-pane-md.split-pane-visible>.split-pane-side {
  max-width: 6%;
  min-width: 40px;
}
</style>
