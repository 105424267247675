<template>
  <ion-col size="8" offset="2">
    <ion-header>
      <ion-item>
        <ion-label position="stacked">Unit of measurement</ion-label>
        <ion-buttons slot="end">
          <ion-button @click="isMetric = true" :color="metric">°C</ion-button>
          <ion-button @click="isMetric = false" :color="metric">°F</ion-button>
        </ion-buttons>
      </ion-item>
    </ion-header>
    <ion-item>
      <ion-label position="floating">{{ 'Oil temperatures in operation' }}</ion-label>
      <ion-select :value="selectedOption" @ionChange="selectedOption = $event.target.value" placeholder="Select One">
        <ion-select-option v-for="(data, index) in options" :value="index" :key="index">
          <div v-if="isMetric">{{ data.c }}</div>
          <div v-else>{{ data.f }}</div>
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-col>
</template>

<script>
export default {
  props: ['options'],
  data() {
    return {
      isMetric: true,
      metric: 'primary',
      selectedOption: ''
    }
  },
  computed: {
    isDisabled() {
      return this.selectedOption ? true : false
    }
  },
  watch: {
    selectedOption(val) {
      this.$emit('temp-chosen', val)
    }
  }
}
</script>