import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue'

import routes from './routes'

Vue.use(IonicVueRouter)

export default new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
