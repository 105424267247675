<template>
  <div class="table-wrapper">
    <table>
      <tr class="row table-header">
        <th>Product</th>
        <th v-if="this.$route.params.sizeId !== 4">Avg. Quantity Consumed</th>
        <th v-else>Percentage usage</th>
        <th>Your Consumption</th>
        <th>Estimated Lubricant Demand</th>
      </tr>
      <TableRow class="row" v-for="(row, index) in tableData" :key="index" :row="row" :index="index"/>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableRow from './TableRow.vue'

export default {
  components: {
    TableRow
  },
  computed: {
    ...mapState('lubeAnalyser', ['tableData'])
  }
}
</script>
