<template>
  <ion-col size-lg="3" size-sm="6" size="12" class="ion-padding-bottom">
    <ion-card @click="plantSize(Number(size.value))" class="ion-activatable toptext-icon" color="light">
      <ion-card-content>
        <ion-text class="ion-text-uppercase" color="primary">{{ size.name }}</ion-text>
      </ion-card-content>
      <ion-item v-if="plantSizeOutput(size.value)" lines="none">
        <ion-card-title>{{ plantSizeOutput(size.value) }}</ion-card-title>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-item v-else lines="none">
        <ion-card-title>{{ size.name }}</ion-card-title>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['size'],
  computed: {
    ...mapState('lubeAnalyser', ['sizeRange', 'energyType'])
  },
  methods: {
    plantSizeOutput (index) {
      index = index - 1
      if (this.sizeRange[index]) {
        return this.sizeRange[index] + ' ' + this.energyType
      }
    },
    plantSize (size) {
      this.$router.push({name: 'e-tools.cross_sell_calculator.size.calculation', params: { industryId: this.$route.params.industryId, sizeId: size }})
    }
  }
}
</script>
