<template>  
  <ion-col size-lg="3" size-md="4" size-sm="6" size="12" class="ion-margin-bottom">
    <ion-card color="light" class="ion-text-center ion-activatable flex-bottom" @click="openModal()">
      <div class="logo-image" :class="data.tier.toLowerCase()">
        <ion-img :src="image"/>
      </div>
      <ion-card-content>
        <ion-text color="premium">
          <p class="ion-text-uppercase">{{ data.endorsement_type }}</p>
        </ion-text>
        <div class="heading ion-margin-bottom ion-padding-bottom">
          <span v-for="(product, name, index) in data.products" :key="name"><span v-if="index != 0">, </span><b><ion-text color="primary" class="heading ion-text-uppercase">PETRONAS </ion-text>{{ product }}</b></span>
        </div>

        <ion-text color="premium">
          <p>
            <span v-for="(cat, name, index) in data.categories" :key="name"><span v-if="index != 0">, </span>{{ cat }}</span><span>, </span>
            <span v-for="(ind, name, index) in data.industries" :key="name"><span v-if="index != 0">, </span>{{ ind }}</span><span>, </span>{{ data.country }}</p>
        </ion-text>
        <ion-text>
          <p>Company Name: {{ company }}</p>
        </ion-text>
      </ion-card-content>
      <ion-footer>
        <ion-toolbar color="light">
          <ion-buttons slot="start" v-if="online">
            <ion-button size="small" color="primary" slot="end" fill="clear">
              <ion-icon size="small" slot="icon-only"/>
            </ion-button>
          </ion-buttons>
          <ion-title size="small">{{ data.oem_equipment }}</ion-title>
          <ion-buttons slot="end" v-if="online">
            <ion-button size="small" color="primary" slot="end" fill="clear" @click.stop="downloadFile()">
              <ion-icon size="small" slot="icon-only" name="download"/>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
import Axios from 'axios';
import Modal from './modal.vue'

export default {
  props: {
    data: Object,
    image: String,
    company: String,
    online: Boolean
  },
  data () {
    return {
      currentModal: null
    }
  },
  computed: {
    title() {
      return this.data.title
    },
    modalData() {
      return this.data.info.filter(i => i.content)
    },
    fileUrl() {
      return this.data.file_url
    },
    fileName() {
      return 'proof-of-performance-' + this.data.id + '.pdf'
    }
  },
  methods: {
    async openModal() {
      const modal = await this.$ionic.modalController
        .create({
          component: Modal,
          componentProps: {
            data: {
              content: this.modalData,
              fileUrl: this.fileUrl
            },
            propsData: {
              online: this.online,
              title: this.title,
              tier: this.data.tier.toLowerCase()
            },
          },
        })
        await modal.present()
        this.currentModal = modal
    },
    downloadFile() {
      // console.log(this.fileUrl)
      Axios({
        url: this.fileUrl,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', this.fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    }
  }
}
</script>

<style lang="scss">
.heading {
  font-weight: 800 !important;
}
</style>