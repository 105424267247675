import Vue from 'vue'
import Vuex from 'vuex'

import products from './modules/products'
import lubeAnalyser from './modules/lube-analyser'
import content from './modules/content'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    lubeAnalyser,
    products,
    content
  }
})

export default store
