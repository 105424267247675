<template>
  <ion-page>
    <top-toolbar/>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <ProductLinkBlock v-for="(tile,i) in tiles" :data="tile" :key="i"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import ProductLinkBlock from '../components/products/blocks/ProductLinkBlock.vue'

export default {
  components: {
    ProductLinkBlock
  },
  data() {
    return {
      posts: [],
      errors: [],
      tiles: [
        {
          title: "By Industry",
          description: "Choose products by industry, such as Cement, Mining or Steel.",
          route: "products.by_industry",
          image:
            "/assets/images/bg/by-industry.jpg"
        },
        {
          title: "By Product",
          description: "Choose products by type, such as marine oil, turbine oil or hydraulic oil.",
          route: "products.by_product",
          image:
            "/assets/images/bg/by-product.jpg"
        },
        {
          title: "OEM approvals",
          description: "Easily find the certifications you need, for total confidence in the product.",
          route: "products.approvals",
          image:
            "/assets/images/bg/oem_approvals_app.jpg"
        },
        {
          title: "Proof of Performances",
          description: "Search all customer feedback to see how products are performing.",
          route: "products.performance",
          image:
            "/assets/images/bg/pop_app.jpg"
        }
      ],
      text: `<p>PETRONAS Lubricants International’s truly comprehensive range allows you to find the right product for the right application. Here’s the tool that will help you do it with complete accuracy.</p>`
    }
  },
  name: 'products'
}
</script>
