<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>      
        <ion-button @click="sendBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>E-Tools</ion-label>  
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col class="ion-padding" size-md="8" size="12">
            <h1 class="title">Choose the right type of oil for your application</h1>
            <ion-text color="dark">From Air Compressors to Turbines, whatever the equipment, use this Lube Selector to find exactly the right PETRONAS product to keep it running at optimum efficiency.</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <lube-recommender-block v-for="(tile, i) in tiles" :key="i" :tile="tile" />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import LubeRecommenderBlock from "./blocks/LubeRecommenderBlock.vue"
import route from './../../mixins/route'

export default {
  mixins: [route],
  components: {
    LubeRecommenderBlock
  },
  data() {
    return {
      tiles: [
        {
          title: "Air Compressor Oil",
          route: "e-tools.lube_tools.air_compressor_oil",
          icon:
            "/assets/images/lube-recommender/compressor.svg"
        },
        {
          title: "Circulating Oil",
          route: "e-tools.lube_tools.circulating_oil",
          icon:
            "/assets/images/lube-recommender/circulation.svg"
        },
        {
          title: "Gear Oil",
          route: "e-tools.lube_tools.gear_oil",
          icon:
            "/assets/images/lube-recommender/gears.svg"
        },
        {
          title: "Hydraulic Oil",
          route: "e-tools.lube_tools.hydraulic_oil",
          icon:
            "/assets/images/lube-recommender/hydraulic-oil.svg"
        },
        {
          title: "Turbine Oil",
          route: "e-tools.lube_tools.turbine_oil",
          icon:
            "/assets/images/lube-recommender/turbine.svg"
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.block-icon {
  ion-subtitle {
    font-weight: 900;
  }
}
</style>