<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBack()">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>{{ previous.title }}</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content
      :scroll-events="true"
      @ionScrollStart="logScrollStart()"
      @ionScrollEnd="logScrollEnd()"
    >
      <ion-grid fixed>

        <ion-toolbar color="white" v-if="pages > 1" class="ion-hide-lg-up">
          <ion-buttons color="white"  slot="end">
            <ion-button size="large" fill="clear" @click="prev" :disabled="currentIndex <= 1"><ion-icon size="small" slot="icon-only" name="md-arrow-back"/></ion-button>
            <ion-text size="large"  color="primary" class="ion-padding">{{ currentIndex }} of {{ pages }}</ion-text>
            <ion-button size="large" fill="clear" @click="next" :disabled="currentIndex >= pages"><ion-icon size="small" slot="icon-only" name="md-arrow-forward"/></ion-button>
          </ion-buttons>
        </ion-toolbar>

        <ion-toolbar color="white">
          <ion-text color="dark">
            <h1 class="title">{{ title }}</h1>
          </ion-text>
          <ion-buttons color="white" v-if="pages > 1" slot="end" class="ion-hide-md-down">
            <ion-button size="large" fill="outline" @click="prev" :disabled="currentIndex <= 1"><ion-icon size="small" slot="icon-only" name="md-arrow-back"/></ion-button>
            <span v-for="(n,index) in pages" :key="index">
              <ion-button size="large"  color="primary" fill="solid" v-if="currentIndex === n">{{ n }}</ion-button>
              <span v-else>
                <span v-if="!currentIndex === n || n === currentIndex - 1 || n === currentIndex + 1 || n === pages">
                  <ion-button size="large" v-if="currentIndex < pages - 2 && n === pages">...</ion-button>
                  <ion-button size="large" fill="outline" @click="currentIndex = n">{{ n }}</ion-button>
                </span>
              </span>
            </span>
            <ion-button size="large" fill="outline" @click="next" :disabled="currentIndex >= pages"><ion-icon size="small" slot="icon-only" name="md-arrow-forward"/></ion-button>
          </ion-buttons>
        </ion-toolbar>

        <ion-row id="lesson">
          <ion-col size-md="8" offset-md="2" size="12">
            <div v-for="i in [currentIndex]" :key="i" class="slide">
              <div v-for="(section,i) in currentSlide" :key="i">
                <div v-if="section.bundle === 'text'" v-html="section.text"/>
                <h2 v-else-if="section.bundle === 'heading'">{{ section.title }}</h2>
                <h2 v-else-if="section.bundle === 'page_break'">{{ section.title }}</h2>
                <figure v-else-if="section.bundle === 'image'">
                  <img :src="section.image"/>
                  <figcaption v-if="section.caption">{{ section.caption }}</figcaption>
                </figure>
                <ion-list v-else-if="section.bundle === 'list'">
                  <ion-item v-for="(li,i) in section.content" :key="i">
                    <ion-button expand="full" size="large" fill="clear" slot="start">{{ i + 1 }}</ion-button>
                    <ion-label text-wrap>
                      <span v-for="(content,i) in li" :key="i">
                        <h2 v-if="content.type === 'h2'">{{ content.content }}</h2>
                        <div v-if="content.type === 'text'" v-html="content.content"/>
                      </span>
                    </ion-label>
                  </ion-item>
                </ion-list>
                <ol v-else-if="section.bundle === 'ol'">
                  <li v-for="(li,i) in section.content" :key="i">
                    <ion-label text-wrap>
                      {{ li }}
                    </ion-label>
                  </li>
                </ol>
                <ul v-else-if="section.bundle === 'ul'">
                  <li v-for="(li,i) in section.content" :key="i">
                    <ion-label text-wrap>
                      {{ li }}
                    </ion-label>
                  </li>
                </ul>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-footer v-if="showFooter">
          <ion-toolbar color="white">
            <ion-button size="small" slot="start" fill="clear" @click="scrollToTop()" v-if="scrolledToBottom"><ion-icon size="small" slot="start" name="md-arrow-up"/>Scroll to top</ion-button>
            <ion-buttons color="white" v-if="pages > 1" slot="end" class="ion-hide-md-down">
              <ion-button size="large" fill="outline" @click="prev" :disabled="currentIndex <= 1"><ion-icon size="small" slot="icon-only" name="md-arrow-back"/></ion-button>
              <span v-for="(n,index) in pages" :key="index">
                <ion-button size="large"  color="primary" fill="solid" v-if="currentIndex === n">{{ n }}</ion-button>
                <span v-else>
                  <span v-if="!currentIndex === n || n === currentIndex - 1 || n === currentIndex + 1 || n === pages">
                    <ion-button size="large" v-if="currentIndex < pages - 2 && n === pages">...</ion-button>
                    <ion-button size="large" fill="outline" @click="currentIndex = n">{{ n }}</ion-button>
                  </span>
                </span>
              </span>
              <ion-button size="large" fill="outline" @click="next" :disabled="currentIndex >= pages"><ion-icon size="small" slot="icon-only" name="md-arrow-forward"/></ion-button>
            </ion-buttons>
            <ion-toolbar color="white" v-if="pages > 1" class="ion-hide-lg-up">
              <ion-buttons color="white"  slot="end">
                <ion-button size="large" fill="clear" @click="prev" :disabled="currentIndex <= 1"><ion-icon size="small" slot="icon-only" name="md-arrow-back"/></ion-button>
                <ion-text size="large"  color="primary" class="ion-padding">{{ currentIndex }} of {{ pages }}</ion-text>
                <ion-button size="large" fill="clear" @click="next" :disabled="currentIndex >= pages"><ion-icon size="small" slot="icon-only" name="md-arrow-forward"/></ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-toolbar>
        </ion-footer>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { get } from "../../mixins/storage";

export default {
  data() {
    return {
      currentIndex: 1,
      lesson: {},
      title: '',
      pages: 1,
      html: {},
      previous: {
        name: this.$route.params.from.name,
        title: this.$route.params.from.title
      },
      scrolledToBottom: false,
      showFooter: false
    }
  },
  mounted () {
    this.$route.name.includes('cogp') ? this.getCodesOfGoodPractice('APP_COGP') : this.getCodesOfGoodPractice('APP_OBP')
  },
  methods: {
    logScrollStart() {
      // console.log('STARTED')
      this.showFooter = true
    },
    // logScrolling($event) {
    //   console.log('SCROLLING', $event)
    // },
    logScrollEnd() {
      // console.log('ENDED')
      this.scrolledToBottom = true
    },
    next() {
      this.currentIndex += 1
    },
    prev() {
      this.currentIndex -= 1
    },
    goBack() {
      this.$router.push({
        name: this.previous.name
      })
    },
    hideFooter() {
      this.scrolledToBottom = false
      this.showFooter = false
    },
    getContent() {
      return document.querySelector('ion-content')
    },
    scrollToTop() {
      this.getContent().scrollToTop(500)
    },
    goToPage(i) {
      this.currentIndex = i
    },
    async getCodesOfGoodPractice($name) {
      const blocks = await get($name)
      this.lesson = blocks[this.$route.params.id]
      this.title = this.lesson.title
      this.html = this.lesson.html
      this.pages = Object.keys(this.html).length
    }
  },
  computed: {
    currentSlide() {
      this.hideFooter()
      return this.html[this.currentIndex]
    }
  },
  watch: {
    currentIndex() {
      
    }
  }
}
</script>

<style lang="scss">
#lesson {
  line-height: 150%;
   h2 {
    font-weight: 800;
  }
  h3 {
    font-weight: 800;
    color: #008e89;
  }
  h4 {
    font-weight: 800;
    color: #555;
  }
  strong, b {
    font-weight: 900;
  }
  figure {
    text-align: center;
  }
  li {
    margin-bottom: 10px;
  }
  blockquote {
    padding: 20px;
    max-width: 20px;
    background-color: #008e89;
    color: white;
    p {
      font-weight: 700;
    }
  }
  ul {
    list-style: none; /* Remove default bullets */
    li {
      h2, h3, h4 {
        display: inline;
      }
    }
    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #008e89; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  ol {
    list-style: none; 
    counter-reset: li;
    li {
      counter-increment: li;
      h2, h3, h4 {
        display: inline;
      }
      &::before {
        content: counter(li); 
        color: #008e89;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-weight: bold;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
      }
    }
  }
}
 
</style>