export default {
  methods: {
    sendBack () {
      const routeArr = this.$route.name.split('.')
      routeArr.pop()
      const routeStr = routeArr.join('.')
      this.$router.push({ name: routeStr })
    }
  }
}
