<template>
  <ion-page>
    <top-toolbar/>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <ImageCard 
            v-for="(block,i) in items" 
            :key="i" 
            col="6"
            :title="block.title" 
            :description="block.description" 
            :imageName="block.image"
            cardClass="small-card"
            :link="block.link"
          />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// import _filter from 'lodash/filter'
import JSON from '../components/know-how/data/blocks.json'
import ImageCard from '../components/blocks/ImageCard.vue'
import { get } from '../mixins/storage'

export default {
  name: 'KnowHow',
  components: {
    ImageCard
  },
  computed: {
    items () {
      return this.blocks
    }
  },
  data() {
    return {
      text: '<p>Learn our codes of good practice for ensuring we provide the highest level of service and expertise to our customers.</p>',
      blocks: JSON.blocks,
      obp: 0,
      cogp: 0
    }
  },
  mounted () {
    this.getLessonCounts()
  },
  methods: {
    async getLessonCounts() {
      this.obp = await get('APP_OBP_COUNT')
      this.cogp = await get('APP_COGP_COUNT')
      this.checkForContent()
    },
    checkForContent() {
      this.blocks = this.blocks.filter(block => this[block.count] > 0)
    }
  }
}
</script>