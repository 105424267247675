<template>
  <ion-col size-lg="4" size-sm="6" size="12" class="ion-padding-bottom">
    <ion-card class="block-icon ion-activatable" @click="goTo(tile.route)" color="grey">
      <ion-card-content>
        <img height="100" :src="tile.icon" />
      </ion-card-content>
      <ion-item lines="none">
        <ion-subtitle>{{ tile.title }}</ion-subtitle>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"/>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>

export default {
  props: ["tile"],
  data() {
    return {};
  },
  methods: {
    goTo (name) {
      this.$router.push({
        name: name
      })
    }
  }
}
</script>
