<template>
  <ion-page>

    <top-toolbar>
      <template v-slot:left>
        <ion-buttons slot="start" v-if="$route.params.from.name.startsWith('e-tools')">
          <ion-button @click="goToRecommenderBeginning" size="small">
            Start a new selection
            <ion-icon name="md-arrow-back" slot="start"/>
          </ion-button>
        </ion-buttons>

        <ion-buttons slot="start" v-else-if="$route.params.from">
          <ion-button @click="goBack"><ion-icon slot="start" name="md-arrow-back"/><ion-label>Back</ion-label></ion-button>
        </ion-buttons>
      </template>
    </top-toolbar>

    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title" v-if="$route.params.from.name.startsWith('e-tools')">Your recommended product is...</h1>
            <h1 class="title" v-else>Product details</h1>
          </ion-col>
        </ion-row>

        <ion-toolbar>
          <ion-buttons slot="end" v-if="GET_OTHER_COMP_ITEMS.length > 0">
          <ion-button @click="compareProducts()" slot="end" color="primary" fill="outline" v-if="!compare">Compare</ion-button>

          <ion-button
            slot="end"
            color="primary"
            fill="outline"
            @click="productsComparison()"
            v-if="choices.length > 1"
            :disabled="choices.length > 3"
          >Compare {{ choices.length }}</ion-button>

          <ion-button color="danger" fill="clear" @click="compareProducts()" v-if="compare">cancel<ion-icon name="close" slot="end"/></ion-button>
          <ion-button color="success" @click="presentAlert()" v-if="GET_OTHER_COMP_ITEMS.length > 0">
            <ion-icon slot="icon-only" name="information-circle-outline"/>
          </ion-button>
        </ion-buttons>
        </ion-toolbar>
        
        <ion-row>
          <ion-col class="ion-padding">
            <ion-card class="wrapper product-main ion-no-margin" v-if="performance">
              <ion-card-header :color="backColor" class="ion-text-center">
                <ion-item lines="none">
                  <ion-buttons slot="end" v-if="compare">
                    <ion-button size="large" color="white">
                      <ion-icon size="large" slot="icon-only" name="checkbox"/>
                    </ion-button>
                  </ion-buttons>
                </ion-item>
                <object type="image/svg+xml" data="/assets/images/logos/logo-flat-dark.svg" width="64" height="64" class="plilogo" v-if="performance === 'Neutral'"/>
                <object type="image/svg+xml" data="/assets/images/logos/logo-flat-white.svg" width="64" height="64" class="plilogo" v-else/>
                <h1 class="title">{{ 'PETRONAS ' + product.title_short }}</h1>
                <div v-if="product.viscosity.length > 0">
                  <p>VG {{ product.viscosity.join() }} cSt</p>
                </div>
              </ion-card-header>
              <ion-card-content v-if="!compare">
                <ion-grid>
                  <ion-row class="ion-padding-vertical">
                    <ion-col size-md="6" size="12">
                      <ion-list>
                        <ion-item>Product Information</ion-item>
                      </ion-list>
                      <ion-list lines="none">
                        <ion-item class="ion-padding-top">
                          <ion-text v-html="product.description"/>
                        </ion-item>
                        <ion-item class="ion-padding-top">
                          <ion-text v-if="product.footnote"><small v-html="product.footnote"/></ion-text>
                        </ion-item>
                      </ion-list>
                    </ion-col>
                    <ion-col size-md="6" size="12">
                      <ion-list>
                        <ion-item>Benefits</ion-item>
                      </ion-list>
                      <ion-list lines="none">
                        <ion-item>
                          <ion-icon color="primary" slot="start" name="checkmark" class="ion-hide-md-down"/><ion-label text-wrap>{{ product.benefits.main_feature }}</ion-label>
                        </ion-item>
                        <ion-item><ion-icon color="primary" slot="start" name="checkmark" class="ion-hide-md-down"/><ion-label text-wrap>{{ product.benefits.benefit_2 }}</ion-label></ion-item>
                        <ion-item><ion-icon color="primary" slot="start" name="checkmark" class="ion-hide-md-down"/><ion-label text-wrap>{{ product.benefits.benefit_3 }}</ion-label></ion-item>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                  <ion-row v-if="expanded" class="ion-padding-vertical">
                    <ion-col size-md="6" size="12">
                      <ion-list>
                        <ion-item>Product Details</ion-item>
                        <ul>
                          <li>
                            <ion-text :color="typeColor"><strong>Performance: </strong>{{ performance }}</ion-text>
                          </li>
                          <li>
                            <ion-text><strong>Oil type: </strong>{{ product.oil_type }}</ion-text>
                          </li>
                          <li>
                            <ion-text><strong>Category: </strong>{{ product.category.name }}</ion-text>
                          </li>
                          <li v-if="category.performance">
                            <ion-text><strong>Typical performance: </strong>
                              <span v-if="category.tid === '110'">{{ 'SAE ' + category.performance }}</span>
                              <span v-else>{{ 'VG' + category.performance }}</span>
                            </ion-text>
                          </li>
                          <li v-for="(field,i) in fields" :key="i">
                            <ion-text><strong>{{ field.title }}: </strong>{{ field.value }}{{ field.suffix }}</ion-text>
                          </li>
                        </ul>
                      </ion-list>
                    </ion-col>
                    <ion-col size-md="6" size="12">
                      <ion-list v-if="product.approvals.length > 0">
                        <ion-item>Approvals</ion-item>
                        <ul>
                          <li v-for="(approval, index) in product.approvals" :key="index">{{ approval }}</li>
                        </ul>
                      </ion-list>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
              <ion-toolbar color="dark" v-if="compare" class="ion-text-center">
                <ion-text>Performance: {{ performance }}</ion-text>
              </ion-toolbar>
              <ion-toolbar color="light" @click="toggleOpen" class="ion-activatable" v-else>
                <ion-buttons slot="start">
                  <ion-button v-if="expanded"><ion-icon slot="icon-only" name="remove"/></ion-button>
                  <ion-button v-else><ion-icon slot="icon-only" name="add"/></ion-button>
                  <ion-text v-if="expanded">See less</ion-text>
                  <ion-text v-else>See more</ion-text>
                </ion-buttons>
                <ion-ripple-effect/>
              </ion-toolbar>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row v-if="compare">
          <product-block
            v-for="(product, index) in otherProducts"
            :key="index"
            :tick="true"
            :data="product"
            @clicked="onClickChild"
            :cancel="cancel"
          />
        </ion-row>
        <ion-row v-if="!compare && popsLength > 0">
          <ion-col size="12">
            <ion-toolbar>
              <ion-title>Related Proof of Performances</ion-title>
            </ion-toolbar>
          </ion-col>
          <ProofOfPerformancesBlock v-for="pop in popsFiltered" :data="pop" :image="getImage(pop.company)" :company="getCompanyName(pop.company)" :key="pop.id"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductBlock from './blocks/ProductBlock.vue'
import ProofOfPerformancesBlock from '../performance/ProofOfPerformancesBlock.vue'
import { get } from "../../mixins/storage";

export default {
  components: { 
    ProductBlock,
    ProofOfPerformancesBlock
  },
  data () {
    return {
      products: [],
      product: {},
      title: 'Product',
      expanded: false,
      category: {},
      fields: {},
      compare: false,
      otherProducts: [],
      cancel: false,
      choices: [],
      performance: '',
      pop_companies: {},
      pops: []
    }
  },
  mounted () {
    this.getEndorsements()
    this.product = this.GET_PRODUCT(this.$route.params.productId)
    this.performance = this.product.performance
    this.setCurrentCategory(this.product.category.tid)
    this.category = this.GET_CURRENT_CATEGORY
    this.fields = this.product.fields
    this.setCompareProducts()
    this.choices.push(this.product.id)
  },
  computed: {
    ...mapGetters('products', ['GET_PRODUCT', 'GET_CURRENT_CATEGORY', 'GET_OTHER_COMP_ITEMS']),
    typeColor () {
      const color = this.performance.toLowerCase()
      return color === 'neutral' ? 'dark' : color
    },
    backColor () {
      return this.performance.toLowerCase()
    },
    popsFiltered() {
      // return this.pops.filter(e => e.product.value == this.product.product_id)
      return this.pops.filter(e => Object.keys(e.products).includes(this.product.product_id))
    },
    popsLength() {
      return this.popsFiltered.length
    }
  },
  methods: {
    ...mapActions('products', [ 'setCurrentCategory', 'getOtherProducts' ]),
    goTo(route, index) {
      this.$route.params.crumbs.splice(index)
      const newroute = route.slice(0, -1)
      this.$router.push({
        name: this.$route.params.from.name,
        query: {
          route: newroute
        },
        params: {
          from: this.$route,
          crumbs: this.$route.params.crumbs,
          level: index + 1
        }
      })
    },
    async getEndorsements() {
      this.pop_companies = await get('APP_PERFORMANCES_COMPANIES')
      this.pops = await get('APP_PROOF_OF_PERFORMANCES')
    },
    getImage(id) {
      return this.pop_companies[id].image
    },
    getCompanyName(id) {
      return this.pop_companies[id].title
    },
    toggleOpen() {
      this.expanded = !this.expanded
    },
    onClickChild(value) {
      if (this.choices.includes(value)) this.choices = this.choices.filter(choice => choice !== value)
      else this.choices.push(value)
    },
    cancelCompare() {
      this.choices = [this.product.id]
    },
    presentAlert() {
      return this.$ionic.alertController
        .create({
          header: 'Compare',
          // subHeader: '3 Products',
          message: '<p>Click the \'compare\' button to compare products within their product category.</p><p> You can compare up to 3 products</p>',
          buttons: ['OK'],
        })
        .then(a => a.present())
    },
    productsComparison() {
      let query = {
        nid: this.choices.join(","),
        category: this.category.tid,
      }
      if (this.category.filtering) query.section = this.product.added_filtering.value
      this.$router.push({
        name: "products.comparison",
        query: query,
        params: {
          from: this.$route
        }
      })
    },
    goToRecommender() {
      this.$router.push({
        name: this.$route.params.from.name,
        params: {
          productID: this.$route.params.productID,
          from: this.$route,
          level: this.$route.params.level,
          title: this.$route.params.title,
          routeId: this.$route.params.routeId
        }
      })
    },
    goToRecommenderBeginning() {
      this.$router.push({
        name: "e-tools.lube_tools"
      });
    },
    setCompareProducts() {
      const data = {
        id: this.product.id
      }
      if (this.product.filtering) data.section = Number(this.product.added_filtering.value)
      this.getOtherProducts(data)
      this.otherProducts = this.GET_OTHER_COMP_ITEMS
    },
    compareProducts() {
      this.compare = !this.compare
      this.expanded = false
      if (!this.compare) this.cancelCompare()
    },
    goBack() {
      if (this.$route.params.from) this.$router.push(this.$route.params.from.fullPath)
      else this.$router.push('/products')
    }
  }
}
</script>
