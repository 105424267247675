<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="sendBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>E-Tools</ion-label>  
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">{{ text.heading }}</h1>
            <ion-text color="dark">{{ text.sub }}</ion-text>
          </ion-col>
        </ion-row>
        <!-- <ion-row>
          <ion-col class="ion-text-center ion-no-padding">
            <ion-title color="primary">Step 1</ion-title>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center ion-padding-vertical" color="white">
            <template v-for="(radio, i) in ['on', 'off', 'off']">
              <ion-button fill="clear" size="small" color="primary" v-if="radio === 'on'" :key="i"><ion-icon size="small" slot="icon-only" name="radio-button-on"/></ion-button>
              <ion-button fill="clear" size="small" color="primary" v-else :key="i" disabled><ion-icon size="small" slot="icon-only" name="radio-button-off"/></ion-button>
            </template>
          </ion-col>
        </ion-row> -->
        <ion-row v-if="sortFunc">
          <CrossSellBlock v-for="section in sortFunc" :key="section.id" :data="section"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import { mapActions, mapGetters } from 'vuex'
import route from './../../mixins/route'
import CrossSellBlock from './blocks/CrossSellBlock.vue'
import json from './data/calculations.json'

export default {
  mixins: [route],
  components: {
    CrossSellBlock
  },
  data () {
    return {
      text: {
        heading: 'Choose an industry',
        sub: 'Product lists and averages will differ between industries.'
      }
    }
  },
  mounted () {
    this.setData(json.calculations)
  },
  computed: {
    ...mapGetters('lubeAnalyser', ['GET_DATA']),
    sortFunc () {
      let sorted = Object.values(this.GET_DATA)
      return _orderBy(sorted, 'title', 'asc' )
    }
  },
  methods: {
    ...mapActions('lubeAnalyser', ['setData'])
  }
}
</script>