<template>
    <ion-page>
      <top-toolbar>
        <template v-slot:left>
          <ion-button @click="goBack">
              <ion-icon slot="start" name="md-arrow-back"/>
              <ion-label>Explore best practices</ion-label>
            </ion-button>
        </template>
      </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <LessonCard 
            v-for="(block,i) in blocks" 
            :key="i" 
            :title="block.title" 
            :description="block.description" 
            :imageName="block.image"
            cardClass="small-card"
            :id="block.id"
          />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import LessonCard from './LessonCard.vue'
import { get } from '../../mixins/storage'

export default {
  components: {
    LessonCard
  },
  data () {
    return {
      blocks: [],
      text: ''
    }
  },
  mounted () {
    this.getCodesOfGoodPractice()
  },
  methods: {
    goBack() {
      const name = this.$route.name.split('.', 1).toString()
      this.$router.push({
        name: name
      })
    },
    async getCodesOfGoodPractice() {
      this.blocks = await get('APP_OBP')
    }
  }
}
</script>