<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBack()">
          <ion-icon slot="start" name="md-arrow-back" />
          <ion-label>Back</ion-label>
        </ion-button>
      </template>
    </top-toolbar>

    <ion-content class="ion-padding-bottom">
      <ion-grid>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">{{ title }}</h1>
          </ion-col>
        </ion-row>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button slot="end" v-if="products.length == 3" fill="outline" @click="showRemoveProduct()" :color="colorType()">
              {{ textType() }}
            </ion-button>
            <ion-button slot="end" v-else-if="productList.length > 0 && products.length < 3" fill="outline" @click="showChoices()" color="primary">
              Add a product
            </ion-button>
            <ion-button color="success" v-on:click="presentAlert()" v-if="productList.length > 0 || products.length > 2">
              <ion-icon slot="icon-only" name="information-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-grid>
      <ion-grid class="table ion-no-padding ion-padding-vertical">
        <ion-row nowrap>
          <ion-col size-md="2" size-xs="3"/>
          <ion-col v-for="(product,i) in products" :key="i" class="ion-no-padding comparison-col">
            <ion-toolbar class="rounded-header" :color="typeColor(product.performance)">
              <ion-label class="ion-text-uppercase ion-hide-md-down"><div><small><strong>PETRONAS</strong></small></div>{{ product.title_short }}</ion-label>
              <ion-buttons slot="end">
                <ion-button size="small" fill="clear" v-if="!remove && productList.length > 0" @click="showSwap(product.id)">
                  <ion-icon slot="icon-only" name="md-arrow-dropdown"/>
                </ion-button>
                <ion-button size="small" fill="clear" v-if="remove && count > 2" @click="onClickRemove(product.id)">
                  <ion-icon slot="icon-only" name="checkbox"/>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-col>
        </ion-row>
        <ion-row class="ion-hide-lg-up" nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Product</strong></ion-label></ion-col>
          <ion-col v-for="(product,i) in products" :key="i" class="ion-padding shadow">
              <ion-label><div><small><strong>PETRONAS</strong></small></div><strong>{{ product.title_short }}</strong></ion-label>
          </ion-col>
        </ion-row>
        <ion-row nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Performance</strong></ion-label></ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label :color="typeColor(product.performance, true)">{{ product.performance }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3">
            <ion-label>
              <strong v-if="category.tid === '129'">Thickener Type</strong>
              <strong v-else>Oil Type</strong>
            </ion-label>
          </ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label>{{ product.oil_type }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Category</strong></ion-label></ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label>{{ product.category.name }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Key benefits</strong></ion-label></ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label><div>{{ product.benefits.main_feature }}</div><br>
            <div>{{ product.benefits.benefit_2 }}</div><br>
            <div>{{ product.benefits.benefit_3 }}</div></ion-label>
          </ion-col>
        </ion-row>
        <ion-row v-if="category.applications" nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Applications</strong></ion-label></ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label>
              <div v-for="(application,i) in product.applications" :key="i">{{ application }}<br><br></div>
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row v-if="category.performance" nowrap>
          <ion-col class="ion-padding" size-md="2" size-xs="3"><ion-label><strong>Typical performance</strong></ion-label></ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label v-if="category.performance">
              <strong v-if="category.tid === '110'">SAE {{ category.performance }}</strong>
              <strong v-else>VG{{ category.performance }}</strong>
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row v-for="(field,index) in category.fields" :key="index" class="fields" nowrap>
          <ion-col class="ion-padding-start" size-md="2" size-xs="3">
            <ion-toolbar class="ion-no-padding">
              <ion-label>{{ field.title }}</ion-label>
              <ion-buttons
                  slot="end">
                <ion-button
                  color="success"
                  v-if="field.description.length > 100"
                  @click="presentInfo(field)"
                  size="small"
                >
                  <ion-icon
                    slot="icon-only"
                    name="information-circle-outline"
                    size="small"
                  />
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-col>
          <ion-col class="ion-padding shadow" v-for="(product,i) in products" :key="i">
            <ion-label v-if="product.fields[index].value">{{ product.fields[index].value }}{{ field.suffix }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row nowrap class="buttons">
          <ion-col size-md="2" size-xs="3"></ion-col>
          <ion-col v-for="(product,i) in products" :key="i" class="ion-padding button-box">
            <ion-button fill="outline" expand="block" @click=goToProduct(product.id) :color="typeColor(product.performance, true)" class="ion-hide-md-down">
              View product page
              <ion-icon size="small" slot="end" name="md-arrow-forward"/>
            </ion-button>
            <ion-button fill="outline" expand="block" size="small" @click=goToProduct(product.id) :color="typeColor(product.performance, true)" class="ion-hide-lg-up">
              View
              <ion-icon slot="end" name="md-arrow-forward" class="ion-hide-xs-down"/>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import Modal from "./modal.vue"
import Popover from "./popover.vue"
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      ids: this.$route.query.nid,
      cid: this.$route.query.category,
      section: this.$route.query.section,
      products: [],
      category: [],
      industry: [],
      productList: [],
      show: false,
      title: "Product comparison",
      remove: false,
      count: null,
      performanceFields: {}
    };
  },
  mounted() {
    this.fetchProducts()
  },
  computed: {
    ...mapGetters('products', ['GET_COMPARISON_ITEMS', 'GET_CURRENT_CATEGORY', 'GET_CURRENT_INDUSTRY', 'GET_OTHER_COMP_ITEMS'])
  },
  methods: {
    ...mapActions('products', ['getProductsToCompare']),
    colorType() {
      if (this.remove) return 'dark' 
      else return 'primary'
    },
    textType() {
      if (this.remove) return 'Cancel'
      else return 'Remove a product'
    },
    typeColor (performance, type) {
      const color = performance.toLowerCase()
      if (type) return color === 'neutral' ? 'dark' : color
      else return color
    },
    presentAlert() {
      return this.$ionic.alertController
        .create({
          header: 'Compare',
          subHeader: 'Up to 3 Products',
          message: '<p>Click the \'compare\' button to compare products within their product category.</p><p> You can compare up to 3 products</p>',
          buttons: ['OK'],
        })
        .then(a => a.present())
    },
    async showChoices() {
      const popover = await this.$ionic.popoverController
      .create({
        component: Popover,
        componentProps: {
          propsData: {
            list: this.productList,
            title: 'Add a product'
          }
        }
      })
      popover.onDidDismiss().then((data) => {
        if (data.data) this.addProduct(data.data)
      })
      return await popover.present();
    },
    async showSwap(id) {
      const popover = await this.$ionic.popoverController
      .create({
        component: Popover,
        componentProps: {
          propsData: {
            list: this.productList,
            title: 'Swap Product'
          }
        }
      })
      popover.onDidDismiss().then((data) => {
        if (data.data) this.onChildSwap([id, data.data])
      })
      return await popover.present();
    },
    presentInfo(field) {
      return this.$ionic.modalController
        .create({
          component: Modal,
          componentProps: {
            data: {
              content: field.description
            },
            propsData: {
              title: field.title
            }
          }
        })
        .then(m => m.present());
    },
    fetchProducts() {
      this.getProductsToCompare(this.$route.query)
      this.products = this.GET_COMPARISON_ITEMS
      this.category = this.GET_CURRENT_CATEGORY
      this.industry = this.GET_CURRENT_INDUSTRY
      this.count = this.products.length
      this.performanceFields = this.category.fields
      this.productList = this.GET_OTHER_COMP_ITEMS
      this.ids = this.$route.query.nid
      this.cid = this.$route.query.category
      this.section = this.$route.query.section
      this.title = this.category.name + " product comparison"
    },
    getText(text, index) {
      const splitString = text.split("|");
      return splitString[index];
    },
    showProductList() {
      this.show = !this.show;
    },
    addProduct(id) {
      this.ids = this.ids + "," + id;
      this.$router.replace({
        query: {
          nid: this.ids,
          category: this.$route.query.category,
          section: this.$route.query.section,
          industry: this.$route.query.industry
        }
      });
      this.remove = false;
      this.show = false;
    },
    goBack() {
      if (this.$route.query.industry) {
        this.$router.push({
          name: "products.by_industry.industry-type.product-types",
          params: {
            industryId: this.$route.query.industry,
            productId: this.cid,
            from: this.$route
          }
        });
      } else {
        this.$router.push({
          name: "products.by_product.products-types",
          params: {
            productTypeId: this.cid,
            from: this.$route
          }
        });
      }
    },
    showRemoveProduct() {
      this.remove = !this.remove
    },
    onClickRemove(value) {
      let array = this.ids.split(",")
      array = array.filter(choice => choice !== value.toString())
      this.ids = array.join(",")
      this.$router.replace({
        query: {
          nid: this.ids,
          category: this.$route.query.category,
          section: this.$route.query.section,
          industry: this.$route.query.industry
        }
      })
      this.$nextTick(() => {
        this.remove = false;
        this.show = false;
      })
    },
    onChildSwap(value) {
      let array = this.ids.split(",")
      let oldNid = value[0].toString()
      let newNid = value[1].toString()
      array = array.map(function(item) {
        return item === oldNid ? newNid : item
      })
      this.ids = array.join(",");
      this.$router.replace({
        query: {
          nid: this.ids,
          category: this.cid,
          section: this.section,
          industry: this.$route.query.industry
        }
      })
    },
    goToProduct (id) {
      this.$router.push({
        name: 'products.product',
        params: {
          productId: id,
          from: this.$route
        }
      })
    }
  },
  watch: {
    "$route.query.nid"() {
      this.fetchProducts(this.ids, this.cid, this.section)
    }
  }
}
</script>

<style lang="scss">
.table ion-row:nth-child(even) {
  background-color: #EEEEEE;
}
.table ion-row.buttons {
  background-color: #fff;
}
.table .fields ion-toolbar {
--background: transparent;
--ion-color-base: transparent !important;
}
.table .rounded-header {
  border-radius: 8px 8px 0 0;
  padding-left: 12px;
} 
.table .comparison-col {
  padding: 0 12px;
}
.shadow {
  margin: 0 8px;
}
.shadow:before {
  width: 6px;
  content: " ";
  background-image: url('/assets/images/shadow_left_n.png');
  background-position-x: 100%;
  background-repeat: repeat-y;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.shadow:after {
  width: 6px;
  content: " ";
  background-image: url('/assets/images/shadow_right_n.png');
  background-repeat: repeat-y;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.button-box {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px 0 rgba(117,117,117,1);
  margin: 0 14px;
}

@media (max-width: 960px) {
  .table {
    ion-label {
      font-size: 13px;
    }
    .comparison-col {
      padding: 0 4px;
    }
    .shadow {
      margin: 0;
    }
    .button-box {
      margin: 0 6px;
    }
    .ion-padding {
      padding: 8px 12px !important;
    }
  }
}

</style>

