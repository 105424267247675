<template>
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-title>Privacy Statement</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon slot="icon-only" name="close"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
       <ion-text>
        <h1>PETRONAS Industrial &amp; Marine Lubricants Toolbox</h1>
          <p>
            We hereby inform you on the information collected by Petronas Lubricants Italy S.p.A. (“Petronas” or “us”) through the PETRONAS Industrial &amp; Marine Lubricants Toolbox (the “App”).
          </p>
          <p><strong>Contact details</strong></p>
          <p>
            Herein below you may find Petronas’ contact details:
          </p>
          <p>
            Petronas Lubricants Italy S.p.A. with a Sole Shareholder
          <br>
            Via Santena no. 1 - Villastellone (TO)
          <br>
            Register of Enterprises of Turin no. 05082750968
          <br>
            Tax Code and VAT no. 05082750968
          <br>
            Data protection officer (“DPO”): Mr. Nemio Passalacqua
          </p>
          <p>
            Email:
            <a
              href="mailto:privacy.eu@pli-petronas.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              privacy.eu@pli-petronas.com
            </a>
          </p>
          <p><strong>Information collected</strong></p>
          <p>
            “Personal Data” means any information relating to an identified or
            identifiable natural person, i.e. one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier,
            etc.
          </p>
          <p>
            We do not collect your Personal Data through the App.
          </p>
          <p>
            We only receive statistical information from the application
            marketplace where the App is available for download (Apple App Store
            and/or Google Play Store). The information we receive from Apple
            and/or Google is not linked or anyway associated to a particular user
            of the App.
          </p>
          <p>
            The table below shows the categories of information that we may
            receive in relation to the App:
          </p>
            <table border="1" style="border: 1px solid #ddd;width:100%;">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Marketplace</strong>
                      
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Information</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Apple App Store</p>
                  </td>
                  <td>
                    <p>
                    <ul>
                      <li>
                          Number of impressions/views;
                      </li>
                      <li>
                          Product page views;
                      </li>
                      <li>
                          App Units;
                      </li>
                      <li>
                          Number of downloads;
                      </li>
                      <li>
                          Sessions;
                      </li>
                      <li>
                          Active Devices;
                      </li>
                      <li>
                          Crashes.
                      </li>
                    </ul>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                        <p>
                          Google Play Store
                        </p>
                  </td>
                  <td>
                            <p>
                        <ul>
                          <li>
                              App installation related statistics;
                          </li>
                          <li>
                              App Ratings;
                          </li>
                          <li>
                              Crashes.
                          </li>
                        </ul>
                      </p>
                  </td>
                </tr>
              </tbody>
            </table>
          <p>
            In case you have any questions about this privacy statement, please
            feel free to contact us at
            <a
              href="mailto:privacy.eu@pli-petronas.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              privacy.eu@pli-petronas.com
            </a>
          </p>
       </ion-text>
    </ion-content>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String
  }
}
</script>