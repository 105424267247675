import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import TopToolbar from './components/TopToolbar.vue'

import Ionic from '@ionic/vue'
import '@ionic/core/css/ionic.bundle.css'
import VueCarousel from 'vue-carousel'

import { addIcons } from 'ionicons'
import { refresh, download, add, remove, swap, help, gitCompare, radioButtonOn, radioButtonOff, checkmark, arrowUp, informationCircleOutline, informationCircle, checkmarkCircle, home, arrowDropdown, checkmarkCircleOutline, square, checkbox } from 'ionicons/icons'

Vue.use(VueCarousel)
Vue.use(Ionic)
Vue.config.productionTip = false
Vue.component('top-toolbar', TopToolbar)

addIcons({
  'ios-refresh': refresh.ios,
  'md-refresh': refresh.md,
  'ios-download': download.ios,
  'md-download': download.md,
  'ios-add': add.ios,
  'md-add': add.md,
  'ios-remove': remove.ios,
  'md-remove': remove.md,
  'ios-swap': swap.ios,
  'md-swap': swap.md,
  'ios-help': help.ios,
  'md-help': help.md,
  'ios-git-compare': gitCompare.ios,
  'md-git-compare': gitCompare.md,
  'ios-radio-button-on': radioButtonOn.ios,
  'md-radio-button-on': radioButtonOn.md,
  'ios-radio-button-off': radioButtonOff.ios,
  'md-radio-button-off': radioButtonOff.md,
  'ios-checkmark': checkmark.ios,
  'md-checkmark': checkmark.md,
  'ios-arrow-up': arrowUp.ios,
  'md-arrow-up': arrowUp.md,
  'ios-information-circle-outline': informationCircleOutline.ios,
  'md-information-circle-outline': informationCircleOutline.md,
  'ios-information-circle': informationCircle.ios,
  'md-information-circle': informationCircle.md,
  'ios-checkmark-circle': checkmarkCircle.ios,
  'md-checkmark-circle': checkmarkCircle.md,
  'ios-checkmark-circle-outline': checkmarkCircleOutline.ios,
  'md-checkmark-circle-outline': checkmarkCircleOutline.md,
  'ios-home': home.ios,
  'md-home': home.md,
  'md-arrow-dropdown': arrowDropdown.md,
  'ios-square': square.ios,
  'md-square': square.md,
  'ios-checkbox': checkbox.ios,
  'md-checkbox': checkbox.md
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
