<template>
  <ion-page>
    <top-toolbar/>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col v-for="(tile,i) in tiles" :key="i" @click="goTo(tile.name)" class="ion-margin-bottom" :class="tile.blockClass" size-sm="12" size-lg="6">
            <ion-card color="secondary" class="image-back ion-activatable">
              <div class="back-image" :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.95)), url(/assets/images/bg/' + tile.image + ') center center / cover no-repeat', 'background-size': 'cover' }"></div>
              <ion-card-content>
                <ion-card-title class="h1">{{ tile.title }}</ion-card-title>
              </ion-card-content>
              <ion-item lines="none">
                <ion-card-subtitle>{{ tile.description }}</ion-card-subtitle>
                <ion-icon size="large" color="primary" name="md-arrow-forward" slot="end"/>
              </ion-item>
              <ion-ripple-effect/>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>

export default {
  name: 'eTools',
  data() {
    return {
      text: '<p>Explore our collection of E-tools</p>',
      tiles: [
        {
          title: 'Industrial Lube Selector',
          description: 'Choose the right oil for you',
          name: 'e-tools.lube_tools',
          image: 'lubeselector.png',
          blockClass: ''
        },
        {
          title: 'Lube Consumption Analyser',
          description: 'Analyse your lube consumption',
          name: 'e-tools.cross_sell_calculator',
          image: 'lca.jpg',
          blockClass: 'ion-hide-md-down'
        },
        {
          title: 'Hydraulic energy saving calculator',
          description: 'Check your potential savings by adopting PETRONAS Hydraulic ESF',
          name: 'e-tools.hydraulic_energy_saving_calculator',
          image: 'esf.jpg',
          blockClass: 'ion-hide-md-down'
        }
      ]
    };
  },
  methods: {
    goTo (name) {
      this.$router.push({
        name: name
      })
    }
  }
}
</script>
