<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="sendBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Choose an Industry</ion-label>  
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content class="cross-sell-wrapper" v-if="plantSizes">
      <ion-grid fixed>
        <!-- <ion-row>
          <ion-item color="white">
            <ion-buttons slot="start">
              <span v-for="(crumb, i) in crumbs" :key="i">
                <ion-button v-if="i != 0" disabled size="small">/</ion-button>
                <ion-button @click="sendBack" style="text-transform:none;letter-spacing:normal">
                  {{ crumb.title }}
                </ion-button>
              </span>
            </ion-buttons>
          </ion-item>
        </ion-row> -->
        
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title" v-if="energyType === 'Machines'">What size is the plant based on the current equipment:<br><ion-text color="primary">{{ industrydata.title }}</ion-text></h1>
            <h1 class="title" v-else>What size is the plant based on annual output of:<br><ion-text color="primary">{{ industrydata.title }}</ion-text></h1>
            <ion-text color="dark">This will provide you with an average quantity consumed for each product.</ion-text>
          </ion-col>
        </ion-row>

        <!-- <ion-row>
          <ion-col class="ion-text-center ion-no-padding">
            <ion-title color="primary">Step 2</ion-title>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center ion-padding-vertical" color="white">
            <template v-for="(radio, i) in ['on', 'on', 'off']">
              <ion-button fill="clear" size="small" color="primary" v-if="radio === 'on'" :key="i"><ion-icon size="small" slot="icon-only" name="radio-button-on"/></ion-button>
              <ion-button fill="clear" size="small" color="primary" v-else :key="i" disabled><ion-icon size="small" slot="icon-only" name="radio-button-off"/></ion-button>
            </template>
          </ion-col>
        </ion-row> -->
        <ion-row class="wrapper">
          <size-block v-for="size in plantSizes" :key="'key-' + size.value" :size="size"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapState } from "vuex"
import route from './../../mixins/route'
import SizeBlock from "./blocks/SizeBlock.vue"

export default {
  mixins: [route],
  components: {
    SizeBlock
  },
  data () {
    return {
      crumbs: [
        {
          title: 'Choose Industry',
          link: 'e-tools.cross_sell_calculator'
        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.from === 'e-tools.cross_sell_calculator') {
      this.setIndustryData(this.$route.params.industryId)
    }
  },
  computed: {
    ...mapState("lubeAnalyser", ['plantSizes', 'energyType', 'industrydata'])
  },
  methods: {
    ...mapActions("lubeAnalyser", ['setIndustryData'])
  }
}
</script>


