
import LubeTools from '../components/lube-recommender/LubeTools.vue'
import LubeTree from '../components/lube-recommender/LubeTree.vue'
import GearOilData from '../components/lube-recommender/data/gear-oil.json'
import AirCompressorData from '../components/lube-recommender/data/air-compressor.json'
import CirculatingOilData from '../components/lube-recommender/data/circulating-oil.json'
import HydraulicOilData from '../components/lube-recommender/data/hydraulic-oil.json'
import TurbineOilData from '../components/lube-recommender/data/turbine-oil.json'
import CrossSellCalculator from '../components/ils/NewCalculation.vue'
import CrossSellSizeCalculation from '../components/ils/SizeCalculation.vue'
import CrossSellCalculation from '../components/ils/Calculation.vue'
import HESCalculator from '../views/HESCalculator.vue'

const routes = [
  {
    path: '/e-tools/lube-tools',
    name: 'e-tools.lube_tools',
    component: LubeTools,
    meta: {
      title: 'Industrial lube selector',
      link: 'Industrial lube selector'
    }
  },
  {
    path: '/e-tools/lube-tools/gear-oil',
    name: 'e-tools.lube_tools.gear_oil',
    component: LubeTree,
    meta: {
      title: 'Gear Oil',
      tool: GearOilData
    }
  },
  {
    path: '/e-tools/lube-tools/air-compressor-oil',
    name: 'e-tools.lube_tools.air_compressor_oil',
    component: LubeTree,
    meta: {
      title: 'Air Compressor Oil',
      tool: AirCompressorData
    }
  },
  {
    path: '/e-tools/lube-tools/circulating-oil',
    name: 'e-tools.lube_tools.circulating_oil',
    component: LubeTree,
    meta: {
      title: 'Circulating Oil',
      tool: CirculatingOilData
    }
  },
  {
    path: '/e-tools/lube-tools/hydraulic-oil',
    name: 'e-tools.lube_tools.hydraulic_oil',
    component: LubeTree,
    meta: {
      title: 'Hydraulic Oil',
      tool: HydraulicOilData
    }
  },
  {
    path: '/e-tools/lube-tools/turbine-oil',
    name: 'e-tools.lube_tools.turbine_oil',
    component: LubeTree,
    meta: {
      title: 'Turbine Oil',
      tool: TurbineOilData
    }
  },
  {
    path: '/e-tools/lca',
    name: 'e-tools.cross_sell_calculator',
    component: CrossSellCalculator,
    meta: {
      title: 'Cross Sell Calculator'
    }
  },
  {
    path: '/e-tools/hesc',
    name: 'e-tools.hydraulic_energy_saving_calculator',
    component: HESCalculator,
    meta: {
      title: 'Hydraulic energy saving calculator'
    }
  },
  {
    path: '/e-tools/lca/:industryId',
    name: 'e-tools.cross_sell_calculator.size',
    component: CrossSellSizeCalculation,
    meta: {
      title: 'Cross Sell Calculator',
      parent: '/e-tools/lca'
    }
  },
  {
    path: '/e-tools/lca/:industryId/:sizeId',
    name: 'e-tools.cross_sell_calculator.size.calculation',
    component: CrossSellCalculation,
    meta: {
      title: 'Consumption Analyser',
      parent: '/e-tools/lca/:industryId'
    }
  }
]

export default routes
