<template>  
  <ion-col :size-lg="col" :size-sm="midSize" size="12" class="ion-margin-bottom" :class="blockClass">
    <ion-card color="secondary" class="image-back ion-activatable" :class="cardClass" @click="goTo(link)">
      <div class="back-image" :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.95)), url(' + imageUrl(imageName) + ') center center', 'background-size': 'cover' }"></div>
      <ion-card-content v-if="cardClass !== 'slim-card'">
        <ion-card-title class="h1">{{ title }}</ion-card-title>
      </ion-card-content>
      <ion-item lines="none">
        <ion-card-title v-if="cardClass === 'slim-card'" class="h2">{{ title }}</ion-card-title>
        <ion-card-subtitle v-if="description">{{ description }}</ion-card-subtitle>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    imageName: String,
    link: String,
    col: String,
    cardClass: String,
    blockClass: String
  },
  computed: {
    midSize() {
      if (this.link.startsWith('e-tools')) return '12'
      else if (this.link.startsWith('knowhow')) return '12'
      else return this.col !== '12' ? '12' : '6'
    }
  },
  methods: {
    goTo (name) {
      this.$router.push({
        name: name
      })
    },
    imageUrl (image) {
      return '/assets/images/bg/' + image
    }
  }
}
</script>

<style lang="scss">
ion-card.image-back.small-card {
  min-height: 200px;
}
.back-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dark-image .back-image {
  filter: brightness(50%);
}
</style>