
import Lesson from '../components/know-how/Lesson.vue'
import CodesOfGoodPractice from '../components/know-how/CodesOfGoodPractice.vue'
import OtherBestPractices from '../components/know-how/OtherBestPractices.vue'
// import TenPhases from '../components/know-how/data/ten-phases.json'
// import IsoOilCleanliness from '../components/know-how/data/iso-cleanliness.json'
// import LabellingEquipment from '../components/know-how/data/labelling-equipment.json'
// import LubricantManagement from '../components/know-how/data/lubricant-management.json'

const routes = [
  {
    path: '/know-how/cogp',
    name: 'knowhow.cogp',
    component: CodesOfGoodPractice,
    meta: {
      title: 'Codes of good practice'
    }
  },
  {
    path: '/know-how/obp',
    name: 'knowhow.obp',
    component: OtherBestPractices,
    meta: {
      title: 'Other Best Practices'
    }
  },
  {
    path: '/know-how/cogp/:id',
    name: 'knowhow.cogp.lesson',
    component: Lesson
  },
  {
    path: '/know-how/obp/:id',
    name: 'knowhow.obp.lesson',
    component: Lesson
  }
  // {
  //   path: '/know-how/10-phases-of-lubrication',
  //   name: 'knowhow.ten-phases',
  //   component: Lesson,
  //   meta: {
  //     title: '10 Phases of Lubrication',
  //     tool: TenPhases
  //   }
  // },
  // {
  //   path: '/know-how/iso-oil-cleanliness',
  //   name: 'knowhow.iso-oil-cleanliness',
  //   component: Lesson,
  //   meta: {
  //     title: 'ISO Oil Cleanliness',
  //     tool: IsoOilCleanliness
  //   }
  // },
  // {
  //   path: '/know-how/labelling-equipment',
  //   name: 'knowhow.labelling-equipment',
  //   component: Lesson,
  //   meta: {
  //     title: 'Labelling of equipment',
  //     tool: LabellingEquipment
  //   }
  // },
  // {
  //   path: '/know-how/lubricant-management',
  //   name: 'knowhow.lubricant-management',
  //   component: Lesson,
  //   meta: {
  //     title: 'Lubricant management',
  //     tool: LubricantManagement
  //   }
  // }
]

export default routes
