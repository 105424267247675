import _round from 'lodash/round'

const config = {
  namespaced: true,
  state: {
    industrydata: {},
    jsondata: {},
    averages: [],
    picked: {},
    mainValue: {
      name: '',
      index: '',
      percent: '',
      value: ''
    },
    onePercentValue: '',
    dontKnow: false,
    consumption: [],
    crossSellType: {},
    yearlyOutput: [],
    yearlyOutputClean: [],
    minMaxValues: [],
    sizeRange: [],
    energyType: '',
    savedFormData: {},
    savedCrossSellData: [],
    calcId: '',
    sizeId: '',
    plantSizes: [
      { name: 'Small', value: 1 },
      { name: 'Medium', value: 2 },
      { name: 'Large', value: 3 },
      { name: 'I don\'t know yet', value: 4 }
    ],
    percentages: [],
    pickedAverages: [],
    tableData: [],
    subtractions: '',
    tableLength: '',
    finalFigures: [],
    totalUnits: '',
    totalRowIndex: '',
    subsTotal: 0,
    finalTotal: '',
    ratioData: [],
    tdem: '',
    ccon: '',
    estimatedConsumptionValue: ''
  },
  getters: {
    GET_INDUSTRY_DATA: (state) => state.industrydata,
    GET_DATA: (state) => state.jsondata,
    GET_ECV: (state) => state.estimatedConsumptionValue,
    GET_FINAL_TOTAL: (state) => state.finalTotal,
    GET_SAVED_FORM_DATA: (state) => state.savedFormData,
    GET_ROW_AVERAGES: (state) => (index) => state.averages[index],
    GET_ROW_AVERAGE: (state) => (size, index) => {
      if (state.averages[index][size - 1]) return state.averages[index][size - 1]
    },
    GET_PLANTSIZE: (state) => state.sizeId,
    GET_ROW_VALUE: (state) => (index) => {
      if (state.tableData[index].finalFigure) return state.tableData[index].finalFigure
    },
    GET_SUBTRACTIONS: (state) => state.subtractions,
    GET_TOTAL_UNITS: (state) => state.ccon ? state.ccon : state.totalUnits,
    GET_SUBTRACTION_VALUE: (state) => (index) => {
      if (state.subtractions[index]) return state.subtractions[index]
    },
    GET_PICKED: (state) => state.picked,
    GET_MAIN_VALUE: (state) => state.mainValue,
    GET_MAIN_VALUE_FORMATTED: (state) => (name) => {
      if ((name === 'Grease' || name === 'total') && state.mainValue.value > 0) return (state.mainValue.value * 1000)
      else if (state.mainValue.value > 0) return state.mainValue.value
    },
    GET_SUBTRACTION_VALUE_FORMATTED: (state, getters) => (name, index) => {
      if ((name === 'Grease' || name === 'total') && !isNaN(getters.GET_SUBTRACTION_VALUE(index) * 1000)) return (getters.GET_SUBTRACTION_VALUE(index) * 1000)
      else if (!isNaN(getters.GET_SUBTRACTION_VALUE(index))) return getters.GET_SUBTRACTION_VALUE(index)
    },
    GET_TABLE_DATA: (state) => {
      return state.tableData
    },
    GET_LUBE_DEMAND: (state) => (index) => {
      return state.tableData[index].lubeDemand
    }
  },
  mutations: {
    SET_INDUSTRY_DATA (state, index) {
      state.industrydata = state.jsondata[index]
    },
    SET_DATA (state, data) {
      state.jsondata = data
    },
    SET_ECV (state, data) {
      state.estimatedConsumptionValue = data
    },
    SET_CCON (state, data) {
      state.ccon = data
    },
    SET_TDEM (state, data) {
      state.tdem = data
    },
    SET_DONT_KNOW (state) {
      state.dontKnow = true
    },
    SET_PLANTSIZE (state, data) {
      state.sizeId = data
    },
    SET_CALC_ID (state, data) {
      if (data > 0) state.calcId = data
      else state.calcId = ''
    },
    SET_AVERAGES (state, data) {
      state.averages = data
    },
    SET_CROSS_SELL_RATIOS (state, data) {
      state.ratioData = data
    },
    RESET_RATIO_DATA (state) {
      state.ratioData = []
    },
    SET_TABLE_LENGTH (state) {
      state.tableLength = state.tableData.length
      state.totalRowIndex = state.tableLength - 1
    },
    SET_SUBTRACTION_ARRAY_LENGTH (state) {
      state.subtractions = new Array(state.tableLength - 1)
    },
    RESET_SUBTRACTION_ARRAY (state) {
      const length = state.subtractions.length
      state.subtractions = new Array(length)
    },
    SET_SUBTRACTION (state, data) {
      state.subtractions.splice(data.index, 1, data.value)
    },
    SET_SUB_TO_TABLE (state, data) {
      state.tableData[data.index].subtraction = data.value
    },
    RESET_SUB_TO_TABLE (state, index) {
      delete state.tableData[index].subtraction
    },
    CALCULATE_SUB_VALUE (state, data) {
      if (data.value === state.tableData[data.index].lubeDemand) state.tableData[data.index].finalFigure = '0'
      else if (data.value) {
        state.tableData[data.index].finalFigure = state.tableData[data.index].lubeDemand - data.value
        // state.tableData[data.index].real = realValue(data.name, state.tableData[data.index].finalFigure)
      } else state.tableData[data.index].finalFigure = state.tableData[data.index].lubeDemand
    },
    SET_SUB_VALUE (state, index) {
      state.tableData[index].finalFigure = state.tableData[index].lubeDemand - state.tableData[index].subtraction
    },
    SET_SUBS_TO_TOTAL (state) {
      state.subsTotal = state.subtractions.filter(Boolean).reduce((a, b) => a + b, 0)
      state.finalTotal = state.tableData[state.totalRowIndex].lubeDemand - state.subsTotal
      if (state.finalTotal < state.mainValue.value) state.finalTotal = state.mainValue.value
    },
    SET_PERCENTAGES (state, data) {
      const plantSizePercentages = []
      for (const plant of data) {
        const percent = Number(plant) / 100
        plantSizePercentages.push(percent)
      }
      plantSizePercentages.push(0) // Adds a value for I don't know (currently nothing)
      plantSizePercentages.unshift(0) // Adds an empty value at the front
      state.percentages = plantSizePercentages
    },
    SET_CONSUMPTION (state, data) {
      state.consumption = data
    },
    SET_PICKED (state, data) {
      state.picked = data
    },
    SET_PICKED_AVERAGES (state, data) {
      state.pickedAverages = data
    },
    SET_MAIN_VALUE (state, data) {
      state.mainValue = data
    },
    SET_CROSS_SELL_DATA (state, data) {
      state.crossSellType = data
    },
    SET_YEARLY_OUTPUT_CLEAN (state, data) {
      state.yearlyOutputClean = []
      data.forEach(function (value) {
        const newValue = _round(value, 1)
        state.yearlyOutputClean.push(newValue)
      })
    },
    SET_MINMAX_VALUES (state, data) {
      data.shift()
      const minMaxUnits = []
      data.forEach(function (value) {
        const newValue = value * 1000
        minMaxUnits.push(newValue)
      })
      state.minMaxValues = minMaxUnits
    },
    SET_SIZE_RANGE (state, data) {
      const cloneunits = [...data]
      const c = data[2] + ' +'
      const b = data[1] + ' - ' + cloneunits[2]
      const a = '0 - ' + cloneunits[1]
      state.sizeRange = [a, b, c]
    },
    SET_ENERGY_TYPE (state, data) {
      if (data === 'energy') {
        state.energyType = 'MW'
      } else if (data === 'distance') {
        state.energyType = 'M Km'
      } else if (data === 'number') {
        state.energyType = 'Machines'
      } else {
        state.energyType = 'Million Tons'
      }
    },
    SET_PLANT_SIZES (state, data) {
      state.plantSizes = data
    },
    SET_TABLE_DATA (state, data) {
      state.tableData = data
    },
    RESET_MAIN_VALUE (state) {
      state.mainValue = {
        name: '',
        index: '',
        value: '',
        percent: ''
      }
    },
    SET_PERCENT_VALUE (state, data) {
      state.onePercentValue = data.value / data.percent
    },
    RESET_PERCENT_VALUE (state) {
      state.onePercentValue = ''
    },
    RESET_LUBE_DEMAND (state) {
      state.tableData.forEach(element => {
        delete element.lubeDemand
        delete element.finalFigure
        delete element.subtraction
        // delete element.real
      })
    },
    RESET_FORMATTED (state) {
      state.tableData.forEach(element => {
        delete element.formatted
      })
    },
    SET_LUBE_DEMAND (state) {
      state.tableData.forEach(element => {
        element.lubeDemand = _round((element.percentage * state.onePercentValue), 2)
        element.finalFigure = element.lubeDemand
        state.finalFigures.push(element.finalFigure)
        // element.real = realValue(element.name, element.lubeDemand)
      })
    },
    SET_TOTAL_UNITS (state, data) {
      state.totalUnits = data
    },
    RESET_TOTAL_UNITS (state) {
      state.totalUnits = ''
      state.ccon = ''
      state.estimatedConsumptionValue = ''
    },
    SET_FINAL_FIGURES (state) {
      state.finalFigures = []
      state.tableData.forEach(element => {
        state.finalFigures.push(element.finalFigure)
      })
    },
    RESET_FINAL_FIGURES (state) {
      state.finalFigures = []
    },
    SET_FINAL_TOTAL (state, data) {
      state.finalTotal = data
    },
    RESET_FINAL_TOTAL (state) {
      state.finalTotal = ''
    },
    RESET_PICKED (state) {
      state.tableData.forEach(element => {
        if (element.picked === '1') delete element.picked
        if (element.name === state.picked.name) element.picked = '1'
      })
    }
  },
  actions: {
    setIndustryData ({ commit, dispatch, state }, index) {
      commit('SET_INDUSTRY_DATA', index)
      dispatch('setLubeData', state.industrydata)
    },
    setData ({ commit }, data) {
      commit('SET_DATA', data)
    },
    setPlantSize ({ commit }, data) {
      commit('SET_PLANTSIZE', data)
    },
    setTotalUnits ({ commit, state }) {
      let data = ''
      if (state.mainValue.name !== 'total') {
        const usage = state.mainValue.value
        const sub = state.subtractions.filter(Boolean).reduce((a, b) => a + b, 0)
        data = _round((usage + sub) * 1000, 0)
        if (state.subtractions.filter(Boolean).length + 1 === state.totalRowIndex) {
          commit('SET_TOTAL_UNITS', data)
          commit('SET_CCON', '')
        } else {
          commit('SET_CCON', data)
          commit('SET_TOTAL_UNITS', '')
        }
      } else if (state.mainValue.name === 'total') {
        data = state.mainValue.value * 1000
        commit('SET_TOTAL_UNITS', data)
      } else {
        data = ''
      }
    },
    setFinalValue ({ commit, state }) {
      const data = state.tableData[state.totalRowIndex].lubeDemand > state.mainValue.value ? state.tableData[state.totalRowIndex].lubeDemand : state.mainValue.value
      commit('SET_FINAL_TOTAL', data)
      commit('SET_TDEM', data)
    },
    setLubeData ({ commit, dispatch, state }, data) {
      commit('SET_CROSS_SELL_RATIOS', data.ratios)
      commit('SET_ENERGY_TYPE', data.output_type)
      commit('SET_YEARLY_OUTPUT_CLEAN', data.yearly_output)
      commit('SET_SIZE_RANGE', state.yearlyOutputClean)
      commit('SET_CONSUMPTION', data.consumption)
      commit('SET_PERCENTAGES', state.consumption)
      dispatch('createNewAverages', state.consumption)
      commit('SET_MINMAX_VALUES', state.consumption)
    },
    setECV ({ commit, state }) {
      const ecvarray = []
      state.tableData.forEach((element) => {
        if (element.name !== 'total') {
          if (element.subtraction) ecvarray.push(element.subtraction)
          else ecvarray.push(element.lubeDemand)
        }
      })
      const ecv = _round((ecvarray.reduce((a, b) => a + b, 0)) * 1000, 0)
      commit('SET_ECV', ecv)
    },
    setMainValue: ({ commit, dispatch }, newValue) => {
      commit('SET_MAIN_VALUE', newValue)
      if (newValue.name === 'total') dispatch('setTotalUnits')
      dispatch('changePlantSize', newValue.value)
      commit('SET_PERCENT_VALUE', newValue)
      commit('SET_LUBE_DEMAND')
      commit('SET_FINAL_FIGURES')
      dispatch('setFinalValue')
      dispatch('setECV')
      commit('SET_CCON', _round(newValue.value * 1000, 0))
    },
    changePlantSize: ({ state, dispatch }, value) => {
      if (state.dontKnow) {
        const tester = state.pickedAverages.filter(avg => avg < value)
        const test = tester.length + 1 < 4 ? tester.length + 1 : 3
        if (state.sizeId !== test) dispatch('setPlantSize', test)
      }
    },
    setPicked: ({ commit, dispatch }, value) => {
      commit('SET_PICKED', value)
      dispatch('getSizesforPicked', value.index)
      // dispatch('resetStates')
      // commit('RESET_SUBTRACTION_ARRAY')
    },
    setNewPicked: ({ commit, dispatch }, value) => {
      commit('SET_PICKED', value)
      dispatch('getSizesforPicked', value.index)
      dispatch('resetStates')
      commit('RESET_SUBTRACTION_ARRAY')
    },
    resetPicked: ({ commit, dispatch }, value) => {
      commit('SET_PICKED', value)
      dispatch('getSizesforPicked', value.index)
      dispatch('swapMainValue', value)
    },
    swapMainValue: ({ dispatch, commit, state }, value) => {
      const newSub = {
        index: state.mainValue.index,
        value: state.mainValue.value
      }
      const oldSub = {
        index: value.index,
        value: undefined
      }
      const newMain = {
        value: state.tableData[value.index].subtraction ? state.tableData[value.index].subtraction : state.tableData[value.index].lubeDemand,
        index: value.index,
        name: value.name,
        percent: state.tableData[value.index].percentage
      }
      dispatch('setMainValue', newMain)
      commit('SET_SUBTRACTION', oldSub)
      dispatch('swapPickedforTable')
      commit('RESET_SUB_TO_TABLE', value.index)
      dispatch('resetFinalRowTotal')
      dispatch('setSubtraction', newSub)
    },
    swapPickedforTable: ({ state }) => {
      state.tableData.forEach((element) => {
        if (element.name === state.picked.name) {
          element.picked = '1'
        } else if (element.picked) {
          delete element.picked
        }
      })
    },
    resetFinalRowTotal: ({ commit, state }) => {
      state.tableData.forEach((element, index) => {
        if (element.subtraction && element.finalFigure === element.lubeDemand) {
          commit('SET_SUB_VALUE', index)
        }
      })
    },
    getSizesforPicked: ({ commit, state }, index) => {
      commit('SET_PICKED_AVERAGES', state.averages[index])
    },
    resetStates: ({ commit }) => {
      commit('RESET_MAIN_VALUE')
      commit('RESET_LUBE_DEMAND')
      // commit('RESET_PERCENT_VALUE')
      // commit('RESET_FORMATTED')
      commit('RESET_PICKED')
      commit('RESET_FINAL_FIGURES')
      commit('RESET_FINAL_TOTAL')
      commit('RESET_TOTAL_UNITS')
      // commit('RESET_RATIO_DATA')
    },
    setSubtraction: ({ commit, dispatch }, payload) => {
      commit('SET_SUBTRACTION', payload)
      commit('SET_SUB_TO_TABLE', payload)
      commit('CALCULATE_SUB_VALUE', payload)
      commit('SET_SUBS_TO_TOTAL')
      dispatch('setTotalUnits')
      commit('SET_FINAL_FIGURES')
      dispatch('setECV')
    },
    setTableLength: ({ commit }) => {
      commit('SET_TABLE_LENGTH')
      commit('SET_SUBTRACTION_ARRAY_LENGTH')
    },
    setMergedData: ({ commit, dispatch, state }, size) => {
      const percentage = state.percentages[size]
      const newArray = []
      let i = 0
      for (const value of state.ratioData) {
        const avConsumed = value.percentage * percentage
        const object = {
          name: value.name,
          percentage: Number(value.percentage),
          avg: Number(avConsumed)
        }
        if (value.picked === '1') {
          dispatch('setPicked', {
            name: value.name,
            index: i
          })
          object.picked = '1'
        }
        newArray.push(object)
        i++
      }
      let total = {}
      total = {
        name: 'total',
        percentage: 100,
        avg: Number(100 * percentage)
      }
      if (state.picked === 'total') total.picked = '1'
      newArray.push(total)
      commit('SET_TABLE_DATA', newArray)
      dispatch('setTableLength')
    },
    createNewAverages: ({ commit, state }) => {
      const indexGroup = []
      const ratio = [...state.ratioData]
      ratio.push({
        name: 'total',
        percentage: '100',
        picked: '0'
      })
      for (const group of ratio) {
        const sizeGroup = []
        for (const plant of state.consumption) {
          const percent = Number(plant) / 100
          const percents = _round(Number(group.percentage) * percent, 2)
          sizeGroup.push(percents)
        }
        indexGroup.push(sizeGroup)
      }
      commit('SET_AVERAGES', indexGroup)
    }
  }
}

export default config
