<template>
  <div>
    <ion-header>
      <ion-toolbar :color="tier">
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon slot="icon-only" name="close"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col v-for="(item,i) in content" :key="i" size="6" class="ion-padding">
            <p><strong>{{ item.title }}</strong></p>
            <p>{{ item.content }}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String,
    tier: String
  },
  data() {
    return {
      content: 'Content'
    }
  }
}
</script>