<template>
  <ion-col size-lg size-sm="6" size="12">
    <ion-card @click="toggle" :color="className" class="ion-activatable">
      <ion-card-content>
        <ion-card-subtitle>
          {{ indexToAlphabet(index) }}
        </ion-card-subtitle>
      </ion-card-content>
      <ion-card-header>
        <ion-card-title>{{ data.title }}</ion-card-title>
        <ion-card-subtitle v-if="data.subtitle">{{ data.subtitle }}</ion-card-subtitle>
      </ion-card-header>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
export default {
  props: ['data', 'index'],
  data () {
    return {
      isChosen: false,
      choice: 0,
      className: 'grey'
    }
  },
  computed: {
    idClass () {
      const index = this.index + 1
      return 'id-' + index
    },
    classSelected () {
      if (this.isChosen === true) {
        return 'selected'
      }
      return ''
    }
  },
  methods: {
    toggle () {
      this.isChosen = !this.isChosen
      var isTitle = null
      if (this.isChosen === true) {
        this.choice = 1
        this.className = 'lightgreen'
        isTitle = this.data.title
      } else {
        this.choice = 0
        this.className = 'grey'
        isTitle = null
      }
      this.newData = this.index + ':' + this.isChosen
      this.$emit('selected', { isChosen: this.choice, id: this.index, istitle: isTitle })
    },
    indexToAlphabet (index) {
      const alphabet = 'ABCDEFGH'
      return alphabet.charAt(index)
    }
  }
}
</script>
