import { Plugins } from '@capacitor/core'

const { Network, Device } = Plugins

export async function networkStatus () {
  const status = await Network.getStatus()
  // console.log('STATUS', status)
  return status
}

export async function getDevice () {
  const info = await Device.getInfo()
  // console.log('INFO', info)
  return info
}