<template>
  <ion-item v-if="mainValue.name && mainValue.name !== name && name === 'total'">
    <ion-label>{{ formattedTotal }} {{ unitType }}</ion-label>
    <ion-input
      v-model.number="formattedTotal"
      readonly="true"
    />
  </ion-item>
  <ion-item v-else-if="mainValue.name && mainValue.name !== name && mainValue.name !== 'total'">
    <ion-label position="stacked">Additional usage ({{ unitType }})</ion-label>
     <ion-input
      :value="subtractions"
      v-model.number="subtractions"
      @ionChange="subtractions = $event.target.value"
      type="number"
      inputmode="decimal"
      debounce="400"
    />
  </ion-item>
  <ion-item v-else-if="mainValue && picked.name === name" color="lightgreen">
    <ion-label position="floating" v-if="actualUsage">Primary quantity ({{ unitType }})</ion-label>
    <ion-label position="floating" v-else>Enter primary quantity ({{ unitType }})</ion-label>
    <ion-input
      v-model.number="actualUsage"
      :value="actualUsage"
      @ionChange="actualUsage = $event.target.value"
      type="number"
      inputmode="decimal"
      debounce="400"
    />
  </ion-item>
  <ion-item v-else>
    <ion-label position="stacked">{{ unitType }}</ion-label>
    <ion-input
      readonly="true"
      disabled
    />
  </ion-item>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  props: ['unitType', 'index', 'name', 'percent'],
  computed: {
    ...mapState('lubeAnalyser', ['picked', 'mainValue']),
    ...mapGetters('lubeAnalyser', ['GET_SUBTRACTION_VALUE', 'GET_TOTAL_UNITS', 'GET_MAIN_VALUE_FORMATTED', 'GET_SUBTRACTION_VALUE_FORMATTED']),
    formattedTotal () {
      return this.GET_TOTAL_UNITS.toLocaleString()
    },
    actualUsage: {
      get () {
        return this.GET_MAIN_VALUE_FORMATTED(this.name)
      },
      set (value) {
        if (this.name === 'Grease' || this.name === 'total') value = value / 1000
        this.setMainValue({
          name: this.name,
          index: this.index,
          percent: this.percent,
          value: Number(value)
        })
      }
    },
    subtractions: {
      get () {
        return this.GET_SUBTRACTION_VALUE_FORMATTED(this.name, this.index)
      },
      set (value) {
        if (this.name === 'Grease' || this.name === 'total') value = value / 1000
        this.setSubtraction({'index': this.index, 'value': Number(value), 'name': this.name})
      }
    }
  },
  methods: {
    ...mapActions('lubeAnalyser', [ 'setMainValue', 'setSubtraction' ]),
    isReadOnly () {
      if (this.mainValue.name && this.mainValue.name !== this.name) this.readonly = false
      else this.readonly = true
    }
  }
}
</script>
