import { HTTP } from './../../http-common'

export default {
  getProductList (done) {
    HTTP.get('/app/1.0/app_product_list')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  },
  getOemApprovals (done) {
    HTTP.get('/app/1.0/app_oem_approvals')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  },
  getPerformances (done) {
    HTTP.get('/app/1.0/app_proof_of_performances')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  },
  getCOGP (done) {
    HTTP.get('/app/1.0/app_codes_of_good_practice')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  },
  getOBP (done) {
    HTTP.get('/app/1.0/app_other_best_practice')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  },
  getContent (done) {
    HTTP.get('/app/1.0/app_content')
      .then((response) => {
        done(true, response.data)
      })
      .catch((error) => {
        done(false, error)
      })
  }
}
