<template>  
  <ion-col size-lg="6" size="12" class="ion-margin-bottom">
    <ion-card color="secondary" class="image-back ion-activatable" @click="goTo()">
      <div class="back-image" :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.95)), url(' + imageName + ') center center', 'background-size': 'cover' }"></div>
      <ion-card-content>
        <ion-card-title class="h1">{{ title }}</ion-card-title>
      </ion-card-content>
      <ion-item lines="none">
        <ion-card-subtitle v-if="description">{{ description }}</ion-card-subtitle>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    imageName: String,
    id: Number
  },
  computed: {
  },
  methods: {
    goTo () {
      this.$router.push({
        name: this.$route.name + '.lesson',
        params: {
          id: this.id,
          from: {
            name: this.$route.name,
            title: this.$route.meta.title
          }
        }
      })
    }
  }
}
</script>