<template>
  <ion-col size-md="6" size="12" class="ion-margin-bottom">
    <ion-card color="secondary" class="image-back ion-activatable" @click="goTo(data.route)">
      <div class="back-image" :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.95)), url(' + data.image + ') center center / cover no-repeat', 'background-size': 'cover' }"></div>
      <ion-card-content>
        <ion-card-title class="h1">{{ data.title }}</ion-card-title>
      </ion-card-content>
      <ion-item lines="none">
        <ion-card-subtitle>{{ data.description }}</ion-card-subtitle>
        <ion-icon size="large" color="primary" name="md-arrow-forward" slot="end"/>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    goTo (name) {
      this.$router.push({
        name: name
      })
    }
  }
}
</script>

<style lang="scss">
ion-card.image-back {
  padding-bottom: 10px;
  margin-top: 0;
  ion-item {
    --background: transparent;
    --ion-color-base: primary !important;
  }
}
ion-card.dark-image {
  padding: 10px;
  ion-item {
    --background: transparent;
    --ion-color-base: primary !important;
  }
}
</style>