<template>
  <ion-header>
    <ion-toolbar color="white">
      <ion-buttons slot="start">
        <ion-button @click="goHome" color="primary" class="logo ion-hide-sm-down">
          <ion-icon slot="start" src="/assets/images/logos/petronas-small.svg"/>
          <ion-text color="dark">PETRONAS Lubricants Toolbox</ion-text>
        </ion-button>

        <ion-button @click="goHome" color="primary" class="logo ion-hide-md-up">
          <ion-icon slot="start" src="/assets/images/logos/petronas-small.svg"/>
          <ion-text color="dark" v-if="hasLeftSlot">Lubricants Toolbox</ion-text>
          <ion-text color="dark" v-else>PETRONAS Lubricants Toolbox</ion-text>
        </ion-button>
        <slot name="right"/>
      </ion-buttons>
      <ion-buttons slot="end">
        <slot name="left"/>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
export default {
  computed: {
    hasLeftSlot() {
      return this.$slots['left']
    }
  },
  methods: {
    goHome() {
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss">
.logo ion-text {
  font-size: 130%;
  font-weight: 800;
  letter-spacing: -1px;
  word-spacing: 3px;
  text-transform: none;
  @media (max-width: 960px) {
    // font-size: 100%;
    // word-spacing: 1px;
  }
}
</style>