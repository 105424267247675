<template>
  <div>
    <ion-header>
      <ion-toolbar :color="tier">
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon slot="icon-only" name="close"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col v-for="(item,i) in content" :key="i" size="4" class="ion-padding">
            <small><strong>{{ item.title }}</strong></small><br>
            <small>{{ item.content }}</small>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end" class="ion-margin-end" v-if="online">
          <ion-button color="primary" slot="end" fill="clear" @click="downloadFile()">Download
            <ion-icon size="small" slot="end" name="download"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: 'Modal',
  props: {
    title: String,
    tier: String,
    online: Boolean
  },
  methods: {
    downloadFile() {
      // console.log(this.fileUrl)
      Axios({
        url: this.fileUrl,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'file.pdf')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    }
  },
  data() {
    return {
      content: 'Content',
      fileUrl: 'File'
    }
  }
}
</script>