<template>
  <ion-page>
    <top-toolbar/>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-lg="6" size="12">
            <ion-row>
              <ion-col class="ion-padding-horizontal">
                <h1 class="title">E-tools</h1>
              </ion-col>
            </ion-row>
            <ion-row>
              <ImageCard 
                v-for="(block,i) in tiles" 
                :key="i" 
                col="12" 
                :title="block.title" 
                :description="block.description" 
                :imageName="block.image"
                :cardClass="block.cardClass"
                :blockClass="block.class"
                :link="block.name"
              />
            </ion-row>
          </ion-col>
          <ion-col size-lg="6" size="12">
            <ion-row>
              <ion-col class="ion-padding-horizontal">
                <h1 class="title">Products</h1>
              </ion-col>
            </ion-row>
            <ion-row>
              <ImageCard 
                v-for="(block,i) in explore" 
                :key="i" 
                :col="block.col" 
                :title="block.title" 
                :description="block.description" 
                :imageName="block.image"
                :cardClass="block.cardClass"
                :link="block.name"
              />
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="6" size="12" class="virtualplant ion-hide-md-down">
            <ion-row>
              <ion-col class="ion-padding-horizontal">
                <h1 class="title">Virtual plant</h1>
              </ion-col>
            </ion-row>
            <ion-row style="flex-grow: 2;">
              <ImageCard
                col="12"
                :title="plant.title" 
                :description="plant.description" 
                :imageName="plant.image"
                cardClass="large-card"
                :link="plant.name"
              />
            </ion-row>
          </ion-col>

          <ion-col size-lg="6" size-sm="12" size="12">
            <ion-row>
              <ion-col class="ion-padding-horizontal">
                <h1 class="title">Know-how</h1>
              </ion-col>
            </ion-row>
            <ion-row>
              <ImageCard
                col="12"
                :title="know.title" 
                :description="know.description" 
                :imageName="know.image"
                cardClass="large-card"
                :link="know.name"
              />
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import ImageCard from '../components/blocks/ImageCard.vue'
export default {
  name: "home",
  components: {
    ImageCard
  },
  data() {
    return {
      tiles: [
        {
          title: 'Industrial Lube Selector',
          description: 'Choose the right oil for you',
          name: 'e-tools.lube_tools',
          image: 'lubeselector.png',
          cardClass: 'mini-card'
        },
        {
          title: 'See all our e-tools',
          description: 'Explore our range of interactive E-tools',
          name: 'e-tools',
          image: 'lca.jpg',
          cardClass: 'mini-card'
        }
      ],
      plant: {
        title: "Review a virtual plant",
        description: 'View our virtual plant tool',
        name: 'virtualtour',
        image: 'industrial.jpg'
      },
      explore: [
        {
          title: "By industry",
          name: 'products.by_industry',
          image: 'by-industry.jpg',
          col: '6',
          cardClass: 'mini-card'
        },
        {
          title: "By product",
          name: 'products.by_product',
          image: 'by-product.jpg',
          col: '6',
          cardClass: 'mini-card'
        },
        {
          title: "About our products",
          description: 'Discover our OEM approvals and Proof of Performances',
          name: 'products',
          image: 'oem_approvals_app.jpg',
          cardClass: 'mini-card'
        }
      ],
      know: {
        title: "Best practices",
        description: 'Explore our best practices',
        name: 'knowhow',
        image: 'academy-hero.jpg'
      }
    }
  },
  methods: {
    goTo (name) {
      this.$router.push({
        name: name
      })
    },
    imageUrl (image) {
      return '/assets/images/bg/' + image
    }
  }
}
</script>

<style lang="scss">
.virtualplant {
  display: flex;
  flex-direction: column;
}
ion-card.slim-card {
  min-height: 110px;
}
ion-card.mini-card {
  min-height: 110px;
}
ion-card.large-card {
  min-height: 260px;
}
ion-header {
  z-index: 11;
}
ion-card-subtitle {
  text-transform: none;
}
ion-card {
  &.image-back {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: stretch;
    ion-card-content {
      padding-top: 0;
    }
    ion-item {
      --background: transparent;
      --ion-color-base: primary !important;
    }
  }
  &.dark-image {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &.block-icon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: stretch;
    :nth-child(1) {
      order: 0;
      flex: 1 1 auto;
      align-self: center;
      display: flex;
    }
    :nth-child(2) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }
    ion-item {
      --background: transparent;
      --ion-color-base: primary !important;
    }
  }
  &.toptext-icon {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: stretch;
    align-items: stretch;
    :nth-child(1) {
      order: 0;
      flex: 1 1 auto;
      align-self: flex-start;
      display: flex;
    }
    :nth-child(2) {
      order: 0;
      flex: 0 1 auto;
      align-self: flex-end;
    }
    ion-item {
      --background: transparent;
    }
  }
  &.product-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ion-card-header:nth-child(1) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }
    :nth-child(2) {
      order: 0;
      flex: 2 1 auto;
      align-self: auto;
    }
    ion-item {
      --background: transparent;
    }
  }
  &.product-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    ion-card-header:nth-child(0) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }
    ion-card-content:nth-child(1) {
      order: 0;
      flex:  1 auto;
      align-self: auto;
    }
    ion-toolbar:nth-child(2) {
      order: 0;
      flex: 0 1 auto;
      align-self: auto;
    }

    ion-item {
      --background: transparent;
    }
  }
  &.center-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
</style>