import { set, get, remove } from '../../mixins/storage'
import api from '../../api/product'

const content = {
  namespaced: true,
  state: {
    app_content: {},
    app_oem_approvals: {},
    app_oem_companies: {},
    app_proof_of_performances: {},
    app_endorsement_companies: {},
    app_cogp: {},
    app_obp: {}
  },
  getters: {
    GET_CONTENT: (state) => state.app_content,
    GET_OEM_APPROVALS: (state) => state.app_oem_approvals,
    GET_PROOF_OF_PERFORMANCES: (state) => state.app_proof_of_performances
  },
  mutations: {
    SET_APP_CONTENT (state, data) {
      state.app_content = data
    },
    SET_APP_OEM_APPROVALS (state, data) {
      state.app_oem_approvals = data
    },
    SET_APP_OEM_COMPANIES (state, data) {
      state.app_oem_companies = data
    },
    SET_APP_PROOF_OF_PERFORMANCES (state, data) {
      state.app_proof_of_performances = data
    },
    SET_APP_PERFORMANCES_COMPANIES (state, data) {
      state.app_endorsement_companies = data
    },
    SET_APP_COGP (state, data) {
      state.app_cogp = data
    },
    SET_APP_OBP (state, data) {
      state.app_obp = data
    }
  },
  actions: {
    async setContent ({ getters }) {
      await set('APP_CONTENT', getters.GET_CONTENT)
    },
    async getContent ({ commit }) {
      await api.getContent((success, response) => {
        if (success) {
          commit('SET_APP_CONTENT', response.app_content)
          // set('APP_CONTENT', response.app_content)
        }
      })
    },
    deleteOemApprovals () {
      remove('APP_OEM_APPROVALS')
    },
    async getOemApprovals () {
      const local = await get('APP_OEM_APPROVALS')
      if (!local) {
        await api.getOemApprovals((success, response) => {
          if (success) {
            // commit('SET_APP_OEM_APPROVALS', response.oem_approvals.files)
            set('APP_OEM_APPROVALS', response.oem_approvals.files)
            // commit('SET_APP_OEM_COMPANIES', response.oem_approvals.oem_companies)
            set('APP_OEM_COMPANIES', response.oem_approvals.oem_companies)
            set('APP_OEM_PRODUCTS', response.oem_approvals.product_names)
            set('APP_OEM_CATEGORIES', response.oem_approvals.product_categories)
            set('APP_OEM_COMPANY_ARRAY', response.oem_approvals.oem_company_array)
          }
        })
      }
    },
    deletePerformances () {
      remove('APP_PROOF_OF_PERFORMANCES')
    },
    async getPerformances () {
      const local = await get('APP_PROOF_OF_PERFORMANCES')
      if (!local) {
        await api.getPerformances((success, response) => {
          if (success) {
            // commit('SET_APP_PROOF_OF_PERFORMANCES', response.proof_of_performances.files)
            set('APP_PROOF_OF_PERFORMANCES', response.proof_of_performances.files)
            // commit('SET_APP_PERFORMANCES_COMPANIES', response.proof_of_performances.companies)
            set('APP_PERFORMANCES_COMPANIES', response.proof_of_performances.companies)
            set('APP_PERFORMANCES_PRODUCTS', response.proof_of_performances.product_names)
            set('APP_PERFORMANCES_CATEGORIES', response.proof_of_performances.product_categories)
            set('APP_PERFORMANCES_INDUSTRIES', response.proof_of_performances.industry_ids)
          }
        })
      }
    },
    deleteCOGP () {
      remove('APP_COGP')
    },
    async getCOGP () {
      const local = await get('APP_COGP')
      if (!local) {
        await api.getCOGP((success, response) => {
          if (success) {
            // commit('SET_APP_COGP', response.cogp.files)
            set('APP_COGP', response.cogp.files)
            set('APP_COGP_COUNT', response.cogp.count)
          }
        })
      }
    },
    deleteOBP () {
      remove('APP_OBP')
    },
    async getOBP () {
      const local = await get('APP_OBP')
      if (!local) {
        await api.getOBP((success, response) => {
          if (success) {
            // commit('SET_APP_OBP', response.cogp.files)
            set('APP_OBP', response.cogp.files)
            set('APP_OBP_COUNT', response.cogp.count)
          }
        })
      }
    }
  }
}

export default content
