<template>
  <div>
    <ion-toolbar class="ion-activatable underline" @click="toggleOpen">
      <ion-buttons slot="start">
        <ion-button color="dark" v-if="expanded" disabled>
          <ion-icon slot="icon-only" name="remove"/>
        </ion-button>
        <ion-button color="dark" v-else>
          <ion-icon slot="icon-only" name="add"/>
        </ion-button>
      </ion-buttons>
      <ion-title v-if="section.name">{{ section.name }}</ion-title>
      <ion-ripple-effect/>
    </ion-toolbar>
    <ion-toolbar color="white" v-if="section.products.length > 1" v-show="expanded" class="ion-padding-top">
      <ion-buttons slot="end">
        <ion-button
          v-if="!tick && section.products.length > 1"
          color="secondary"
          fill="outline"
          @click="compareProducts()"
        >Compare
        </ion-button>
        <ion-button
          slot="end"
          color="primary"
          fill="outline"
          @click="productsComparison()"
          v-if="choices.length > 0"
          :disabled="choices.length < 2 || choices.length > 3"
        >Compare {{ choices.length }}
        </ion-button>
        <ion-button color="danger" fill="clear" v-show="tick" @click="cancelCompare()">cancel<ion-icon name="close" slot="end"/></ion-button>
        <ion-button color="success" v-on:click="presentAlert()" v-if="section.products.length > 1">
          <ion-icon slot="icon-only" name="information-circle-outline"/>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-row v-show="expanded" class="ion-padding-vertical">
      <product-block
        v-for="(product, index) in section.products"
        :key="index"
        :tick="tick"
        :data="product"
        @clicked="onClickChild"
        :cancel="cancel"
      />
    </ion-row>
  </div>
</template>

<script>
import ProductBlock from "./blocks/ProductBlock.vue"

export default {
  props: ['section', 'sectionOrder', 'activeItem', 'sectionId'],
  components: {
    ProductBlock
  },
  data() {
    return {
      industries: null,
      products: {},
      categories: {},
      term: {},
      title: this.$route.meta.title,
      tick: false,
      choices: [],
      checked: false,
      cancel: true,
      expanded: false
    };
  },
  mounted() {
    this.setOpen()
  },
  methods: {
    setOpen() {
      if(this.sectionOrder === 0) this.expanded = true
    },
    toggleOpen() {
      if(this.expanded === false) {
        if(this.expanded && this.choices.length > 0) this.cancelCompare()
        this.expanded = !this.expanded
        this.$emit('expanded-item', this.sectionOrder)
      }
    },
    presentAlert() {
      return this.$ionic.alertController
        .create({
          header: 'Compare',
          // subHeader: '3 Products',
          message: '<p>Click the \'Compare\' button to compare products within their product category.</p><p> You can compare up to 3 products</p>',
          buttons: ['OK'],
        })
        .then(a => a.present())
    },
    compareProducts() {
      this.tick = !this.tick;
      this.cancel = false;
    },
    cancelCompare() {
      this.compareProducts();
      this.cancel = true;
      this.choices = [];
    },
    productsComparison() {
      this.$router.push({
        name: "products.comparison",
        query: {
          nid: this.choices.join(","),
          category: this.$route.params.productTypeId,
          industry: this.$route.params.industryId,
          section: this.sectionId
        },
        params: {
          from: this.$route
        }
      });
    },
    onClickChild(value) {
      if (this.choices.includes(value)) this.choices = this.choices.filter(choice => choice !== value)
      else this.choices.push(value)
    }
  },
  watch: {
    activeItem (val) {
      if (val !== this.sectionOrder) this.expanded = false
    }
  }
}
</script>

<style lang="scss">
.underline {
  border-bottom: 1px solid #444;
}
</style>
