<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBackwards($route.query.route)" v-if="crumbs.length > 0 && $route.query.route && $route.query.route.length > 0">
          <ion-icon name="md-arrow-back" slot="start"/>
          {{ crumbs[crumbs.length - 1].title }}
        </ion-button>
        <ion-button @click="goToTools()" v-else>
          <ion-icon name="md-arrow-back" slot="start"/>
          Back to lube selectors
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <!-- <ion-row>
        <ion-col v-if="level > 0">
          <div class="progress">
            <ion-progress-bar color="primary" :value="progress"></ion-progress-bar>
          </div>
        </ion-col>
      </ion-row> -->
      <ion-grid fixed>
        <!-- <ion-row>
          <ion-item color="white">
            <ion-buttons slot="start">
              <span v-for="(crumb, i) in crumbs" :key="i">
                <ion-button v-if="i != 0" disabled size="small">/</ion-button>
                <ion-button @click="goToCrumb(crumb, i)" style="text-transform:none;letter-spacing:normal">
                  {{ crumb.title }}
                </ion-button>
              </span>
            </ion-buttons>
          </ion-item>
        </ion-row> -->
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title" v-if="type === 'TempBlock' ">Select maximum oil temperature in operation</h1>
            <h1 class="title" v-else>{{ question }}</h1>
          </ion-col>
        </ion-row>
        <!-- <ion-toolbar class="ion-text-center" color="white">
          <ion-title>Step {{ level }}</ion-title>
        </ion-toolbar>
        <ion-toolbar class="ion-text-center" color="white">
          <ion-button fill="clear" size="small" color="primary" v-for="(radio, i) in new Array(levels)" :key="i" :disabled="level <= i">
            <ion-icon size="small" slot="icon-only" name="radio-button-on" v-if="level > i"/>
            <ion-icon size="small" slot="icon-only" name="radio-button-off" v-else/>
          </ion-button>
        </ion-toolbar> -->
        <!-- Temp boxes -->
        <template v-if="type === 'TempBlock' ">
          <ion-row>
            <temp-block :options="temperatures" @temp-chosen="tempChosen"/>
          </ion-row>
        </template>
          
        <!-- Multiblocks -->
        <template v-else-if="type === 'MultiBlock'">
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <MultiBlock
              :index="i"
              :key="i"
              v-for="(option, i) in multi.multiOptions"
              :data="option"
              @selected="selectedBlock"
            />
          </ion-row>
          <ion-row>
            <ion-col class="ion-padding">
                <ion-button v-show="multi.chosenOptions !== '000'" @click="multiButton" expand="full" color="lghtgreen" fill="clear">
                  {{ multi.choices.reduce((x, y) => x + y) + ' of ' + multi.arrayLength }} selected - continue
                  <ion-icon name="md-arrow-forward" slot="end"/>
                </ion-button>
            </ion-col>
          </ion-row>
        </template>

        <template v-else>
          <ion-row class="ion-align-items-center ion-justify-content-center">
            <!-- All other blocks -->
            <component
              :is="type"
              :key="index"
              v-for="(option, index) in options"
              :click="blockClick"
              :data="option"
            />
          </ion-row>
        </template>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import IconBlock from "./blocks/IconBlock.vue"
import PlainBlock from "./blocks/PlainBlock.vue"
import TempBlock from "./blocks/TempSection.vue"
import MultiBlock from "./blocks/MultiBlock.vue"

export default {
  components: {
    IconBlock,
    PlainBlock,
    TempBlock,
    MultiBlock
  },
  data() {
    return {
      quizData: this.$route.meta.tool,
      question: this.$route.meta.tool.question,
      options: this.$route.meta.tool.options,
      type: this.$route.meta.tool.type,
      temperatures: this.$route.meta.tool.temperatures,
      steps: this.$route.meta.tool.steps,
      routes: this.$route.meta.tool.routes,
      show: false,
      level: 1,
      multi: {
        multiOptions: null,
        choices: null,
        arrayLength: null,
        chosenOptions: "000",
        isSelected: [null, null, null]
      },
      progress: 0,
      tempOptions: null,
      crumbs: [],
      levels: this.$route.meta.tool.steps.length
    };
  },
  mounted () {
    this.checkQuery()
  },
  methods: {
    checkQuery() {
      if (this.$route.query.route) {
        this.level = this.$route.params.level
        const length = this.$route.query.route.length - 1
        this.quizData = this.$route.meta.tool.steps[length],
        this.question = this.$route.meta.tool.steps[length].question,
        this.options = this.$route.meta.tool.steps[length].options,
        this.type = this.$route.meta.tool.steps[length].type,
        this.crumbs = this.$route.params.crumbs
        if (this.$route.meta.tool.steps[length].type === "MultiBlock") {
          this.multi.multiOptions = this.$route.meta.tool.steps[length].multiOptions
          this.multi.arrayLength = this.$route.meta.tool.steps[length].multiOptions.length
          this.multi.choices = new Array(this.multi.arrayLength).fill(0)
        }
      }
    },
    tempChosen() {
      const val = this.$route.query.route ? this.$route.query.route + this.quizData.options[0].id : this.quizData.options[0].id
      this.$router.push({ query: { route: val }})
    },
    blockClick(event) {
      const val = this.$route.query.route ? this.$route.query.route + event.id : event.id
      this.$router.push({ query: { route: val }})
    },
    multiButton() {
      for (const option of this.options) {
        if (option.selection.includes(this.multi.chosenOptions)) {
          const val = this.$route.query.route ? this.$route.query.route + option.id : option.id
          this.$router.push({ query: { route: val }})
          this.resetMulti()
        }
      }
    },
    resetMulti () {
      this.multi.chosenOptions = "000"
      this.multi.isSelected = [null, null, null]
    },
    findNextStep(id) {
      for (const step of this.steps) {
        if (step.ids.includes(id) === true) {
          this.type = step.type
          this.quizData = step
          this.options = step.options
          this.question = step.question
          if (step.type === "MultiBlock") {
            this.multi.multiOptions = step.multiOptions
            this.multi.arrayLength = step.multiOptions.length
            this.multi.choices = new Array(this.multi.arrayLength).fill(0)
          }
        }
      }
    },
    isProduct(id) {
      for (const route of this.routes) {
        if (route.steps.includes(id) === true) {
          this.goToProduct(route.nid)
        }
      }
    },
    goBackwards(id) {
      this.$route.query.route = id.slice(0, -1)
      this.crumbs = this.crumbs.slice(0, -1)
      this.level = this.level - 1
      if (id.length > 1) {
        this.findNextStep(this.$route.query.route)
      } else {
        this.quizData = this.$route.meta.tool;
        this.question = this.$route.meta.tool.question;
        this.options = this.$route.meta.tool.options;
        this.type = this.$route.meta.tool.type;
      }
    },
    goToTools() {
      this.$router.push({
        name: "e-tools.lube_tools"
      });
    },
    selectedBlock(value) {
      this.multi.choices[value.id] = value.isChosen
      this.multi.isSelected[value.id] = value.istitle
      this.optionString();
    },
    optionString() {
      this.multi.chosenOptions = this.multi.choices
        .toString()
        .replace(/,/g, "")
    },
    goToProduct(id) {
      this.$router.push({
        name: "products.product",
        params: {
          productId: id,
          from: this.$route,
          crumbs: this.crumbs
        }
      })
    },
    setCrumb () {
      let index = this.crumbs.findIndex(c => c.route === this.$route.query.route)
      if (index === -1) {
        this.crumbs.push({
          title: this.quizData.crumb,
          route: this.$route.query.route 
        })
      } else {
        this.crumbs.splice(this.$route.query.route.length)
      }
    },
    goToCrumb(crumb, index) {
      if (index > 0) {
        const newroute = crumb.route.slice(0, -1)
        this.$router.push({
          query: {
            route: newroute
          }
        })
      } else {
        this.level = 1
        this.$router.push({
          name: this.$router.name,
          query: {},
        })
      }
    },
    resetData() {
      this.quizData = this.$route.meta.tool,
      this.question = this.$route.meta.tool.question,
      this.options = this.$route.meta.tool.options,
      this.type = this.$route.meta.tool.type,
      this.temperatures = this.$route.meta.tool.temperatures,
      this.steps = this.$route.meta.tool.steps,
      this.routes = this.$route.meta.tool.routes,
      this.crumbs = []
    }
  },
  watch: {
    '$route.query.route' (val) {
      if (val) {
        this.level = this.$route.query.route.length + 1
        this.setCrumb()
        this.isProduct(val)
        this.findNextStep(val)
      } else {
        this.resetData()
      }
    }
  }
}
</script>
