
import json from '../../components/products/data/response.json'
import { set, get, remove } from '../../mixins/storage'
// import api from '../../api/product'

const products = {
  namespaced: true,
  state: {
    category: {},
    industry: {},
    filtered: false,
    comparison_items: {},
    page_items: {},
    products: {},
    productblocks: {},
    categories: {},
    categoryblocks: {},
    current_category: {},
    industries: {},
    current_industry: {},
    count: 0,
    other_comp_items: [],
    otherProducts: []
  },
  getters: {
    GET_FILTERED: (state) => state.filtered,
    GET_PAGE_ITEMS: (state) => state.page_items,
    GET_PRODUCT: (state) => index => state.products[index],
    GET_PRODUCT_BLOCK: (state) => index => state.productblocks[index],
    GET_CATEGORY_BLOCK: (state) => index => state.categoryblocks[index],
    GET_INDUSTRIES: (state) => state.industries,
    GET_CATEGORIES: (state) => state.categories,
    GET_CURRENT_INDUSTRY: (state) => state.current_industry,
    GET_CURRENT_CATEGORY: (state) => state.current_category,
    GET_COMPARISON_ITEMS: (state) => state.comparison_items,
    GET_OTHER_COMP_ITEMS: (state) => state.other_comp_items
  },
  mutations: {
    SET_CATEGORY (state, data) {
      state.category = data
    },
    SET_CURRENT_CATEGORY (state, index) {
      state.current_category = state.categories[index]
    },
    SET_CURRENT_INDUSTRY (state, index) {
      state.current_industry = state.industries[index]
    },
    SET_INDUSTRY (state, data) {
      state.industry = data
    },
    SET_FILTERED (state) {
      state.filtered = true
    },
    SET_UNFILTERED (state) {
      state.filtered = false
    },
    SET_PAGE_ITEMS (state, data) {
      state.page_items = data
    },
    SET_PRODUCTS (state, data) {
      state.products = data
    },
    SET_PRODUCT_BLOCKS (state, data) {
      state.productblocks = data
      // // set('SET_PRODUCT_BLOCKS', data)
    },
    SET_PRODUCT_COUNT (state, data) {
      state.count = data
      // // set('SET_PRODUCT_COUNT', data)
    },
    SET_INDUSTRIES (state, data) {
      state.industries = data
      // // set('SET_INDUSTRIES', data)
    },
    SET_CATEGORIES (state, data) {
      state.categories = data
      // // set('SET_CATEGORIES', data)
    },
    SET_CATEGORY_BLOCKS (state, data) {
      state.categoryblocks = data
    },
    SET_COMPARISON_ITEMS (state, data) {
      state.comparison_items = data
    },
    SET_OTHER_COMP_ITEMS (state, data) {
      state.other_comp_items = data
    }
  },
  actions: {
    setCategory ({ commit }, data) {
      commit('SET_CATEGORY', data)
      if (data.filtered) {
        commit('SET_PAGE_ITEMS', data.filtered)
        commit('SET_FILTERED')
      } else {
        commit('SET_PAGE_ITEMS', data.products)
        commit('SET_UNFILTERED')
      }
    },
    setCurrentCategory ({ commit }, index) {
      commit('SET_CURRENT_CATEGORY', index)
    },
    setCurrentIndustry ({ commit }, index) {
      commit('SET_CURRENT_INDUSTRY', index)
    },
    // async setProducts ({ commit }) {
    //   await api.getProductList((success, response) => {
    //     if (success) {
    //       // set('SET_PRODUCTS', response.products.list)
    //       commit('SET_PRODUCTS', response.products.list)
    //       // set('SET_PRODUCT_BLOCKS', response.products.blocks)
    //       commit('SET_PRODUCT_BLOCKS', response.products.blocks)
    //       // set('SET_PRODUCT_COUNT', response.products.count)
    //       commit('SET_PRODUCT_COUNT', response.products.count)
    //       // set('SET_CATEGORIES', response.categories.list)
    //       commit('SET_CATEGORIES', response.categories.list)
    //       // set('SET_CATEGORY_BLOCKS', response.categories.blocks)
    //       commit('SET_CATEGORY_BLOCKS', response.categories.blocks)
    //       // set('SET_INDUSTRIES', response.industries)
    //       commit('SET_INDUSTRIES', response.industries)
    //     }
    //   })
    // },
    deleteProductList () {
      remove('APP_PRODUCTS')
    },
    async getProductList ({ commit }) {
      const response = await json
      set('APP_PRODUCTS', response)
      commit('SET_PRODUCTS', response.products.list)
      // set('SET_PRODUCT_BLOCKS', response.products.blocks)
      commit('SET_PRODUCT_BLOCKS', response.products.blocks)
      // set('SET_PRODUCT_COUNT', response.products.count)
      commit('SET_PRODUCT_COUNT', response.products.count)
      // set('SET_CATEGORIES', response.categories.list)
      commit('SET_CATEGORIES', response.categories.list)
      // set('SET_CATEGORY_BLOCKS', response.categories.blocks)
      commit('SET_CATEGORY_BLOCKS', response.categories.blocks)
      // set('SET_INDUSTRIES', response.industries)
      commit('SET_INDUSTRIES', response.industries)
    },
    async getLocalProducts ({ commit }) {
      const local = await get('APP_PRODUCTS')
      commit('SET_PRODUCTS', local.products.list)
      commit('SET_PRODUCT_BLOCKS', local.products.blocks)
      commit('SET_PRODUCT_COUNT', local.products.count)
      commit('SET_CATEGORIES', local.categories.list)
      commit('SET_CATEGORY_BLOCKS', local.categories.blocks)
      commit('SET_INDUSTRIES', local.industries)
    },
    getOtherProducts ({ commit, state }, data) {
      let items = []
      if (data.section) items = state.current_category.sections[data.section].products
      else items = state.current_category.products
      const newItems = items.filter(id => id !== data.id)
      commit('SET_OTHER_COMP_ITEMS', newItems)
    },
    getProductsToCompare ({ commit, state }, data) {
      const nids = data.nid.split(',')
      const compare = []
      nids.forEach(nid => {
        compare.push(state.products[nid])
      })
      commit('SET_COMPARISON_ITEMS', compare)
      if (!data.section && !data.industry) {
        const notPresentInData = state.current_category.products.filter(val => !nids.includes(val.toString()))
        const items = []
        notPresentInData.forEach(nid => {
          items.push(state.productblocks[nid])
        })
        commit('SET_OTHER_COMP_ITEMS', items)
      } else if (data.section && !data.industry) {
        const notPresentInData = state.current_category.sections[data.section].products.filter(val => !nids.includes(val.toString()))
        const items = []
        notPresentInData.forEach(nid => {
          items.push(state.productblocks[nid])
        })
        commit('SET_OTHER_COMP_ITEMS', items)
      } else if (!data.section && data.industry) {
        const notPresentInData = state.current_industry.categories[data.category].products.filter(val => !nids.includes(val.toString()))
        const items = []
        notPresentInData.forEach(nid => {
          items.push(state.productblocks[nid])
        })
        commit('SET_OTHER_COMP_ITEMS', items)
      } else if (data.section && data.industry) {
        const notPresentInData = state.current_industry.categories[data.category].sections[data.section].products.filter(val => !nids.includes(val.toString()))
        const items = []
        notPresentInData.forEach(nid => {
          items.push(state.productblocks[nid])
        })
        commit('SET_OTHER_COMP_ITEMS', items)
      }
    }
  }
}

export default products
