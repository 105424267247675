<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
          <ion-button color="secondary" @click="presentInfo">Privacy Policy
          </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid v-if="!loaded" class="iframeCalculation">
        <ion-row class="ion-justify-content-center ion-align-items-center iframeCalculation">
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-spinner color="secondary" name="lines" size="large"/>
          </ion-col>
        </ion-row>
      </ion-grid>
      <iframe
        @load="load"
        class="iframeCalculation"
        width="100%"
        src="https://pli-petronas-proxy.torpedo.agency"
        v-show="loaded"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import Modal from "./modal.vue"
import { Plugins } from '@capacitor/core'
const { Browser } = Plugins

export default {
  data () {
    return {
      loaded: false
    }
  },
  methods: {
    load() {
      this.loaded = true
    },
    async openBrowser() {
      await Browser.open({ url: "https://pli-portal.com/petronas-industrial-and-marine-lubricants-toolbox/privacy" });
    },
    presentInfo() {
      return this.$ionic.modalController
        .create({
          component: Modal,
          componentProps: {
            data: {
              content: 'Some text'
            }
          }
        })
        .then(m => m.present());
    }
  }
}
</script>

<style lang="scss">
.iframeCalculation {
  height: 100%;
  border: none;
}
</style>
