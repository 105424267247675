<template>
  <ion-page>
    <top-toolbar/>
    <ion-content>
      <ion-grid v-if="!loaded" class="iframeCalculation">
        <ion-row class="ion-justify-content-center ion-align-items-center iframeCalculation">
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-spinner color="secondary" name="lines" size="large"/>
          </ion-col>
        </ion-row>
      </ion-grid>
      <iframe
        @load="load"
        class="iframeCalculation"
        width="100%"
        src="https://petronas.calculator.dynavisdigitaltools.com"
        v-show="loaded"
      />
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  data () {
    return {
      loaded: false
    }
  },
  methods: {
    load() {
      this.loaded = true
    }
  }
}
</script>
