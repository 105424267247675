<template>  
  <ion-col size-lg="3" size-md="4" size-sm="6" size="12" class="ion-margin-bottom">
    <ion-card color="light" class="ion-text-center ion-activatable flex-bottom" :class="data.tier.name" @click="openModal()">
      <div class="logo-image" :class="data.tier.toLowerCase()">
        <ion-img :src="image"/>
      </div>
      <ion-card-content>
        <!-- <ion-text color="primary">
          <p class="heading ion-text-uppercase">Petronas</p>
        </ion-text> -->
        <div class="heading ion-margin-bottom ion-padding-bottom">
          <span v-for="(product, name, index) in data.products" :key="name"><span v-if="index != 0">, </span><b><ion-text color="primary" class="heading ion-text-uppercase">PETRONAS </ion-text>{{ product }}</b></span>
        </div>
        <ion-text color="premium">
          <p v-for="(cat, name, index) in data.categories" :key="name"><span v-if="index != 0">, </span>{{ cat }}</p>
        </ion-text>
      </ion-card-content>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
import Modal from './modal.vue'

export default {
  props: {
    data: Object,
    image: String,
  },
  computed: {
    title() {
      return this.data.title
    },
    modalData() {
      return this.data.info.filter(i => i.content)
    }
  },
  methods: {
    async openModal() {
      const modal = await this.$ionic.modalController
        .create({
          component: Modal,
          componentProps: {
            data: {
              content: this.modalData
            },
            propsData: {
              title: this.title,
              tier: this.data.tier.toLowerCase()
            },
          },
        })
      await modal.present()
    }
  }
}
</script>

<style lang="scss">
.logo-image {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  &.standard {
    border-bottom: 5px solid var(--ion-color-standard);
  }
  &.premium {
    border-bottom: 5px solid var(--ion-color-premium);
  }
  &.supreme {
    border-bottom: 5px solid var(--ion-color-supreme);
  }
  &.neutral {
    border-bottom: 5px solid var(--ion-color-neutral);
  }
  ion-img {
    max-width: 65%;
    margin: auto;
    padding: 10px;
    max-height: 100px;
    img {
      max-height: 100px;
    }
  }
  img {
    max-height: 120px;
  }
}
.flex-bottom {
  display: flex;
  flex-flow: column nowrap;
  ion-footer {
    margin-top: auto;
  }
}
ion-img {
  object-fit: resize;
  height: 100%;
  width: auto !important;
}
</style>