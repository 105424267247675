import Home from '../views/Home.vue'

import KnowHow from '../views/KnowHow.vue'
import ETools from '../views/ETools.vue'
// import Contact from '../views/Contact.vue'
import VirtualTour from '../views/VirtualTour.vue'
import About from '../views/About.vue'

import products from './products'
import tools from './tools'
import knowhow from './knowhow'

import AboutText from '../data/about.json'

const routes = [
  ...products,
  ...tools,
  ...knowhow,
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/know-how',
    name: 'knowhow',
    component: KnowHow,
    meta: {
      title: 'Explore best practices',
      link: 'Know-how'
    }
  },
  {
    path: '/virtual-tour',
    name: 'virtualtour',
    component: VirtualTour,
    meta: {
      title: 'Welcome to the Virtual Tour',
      link: 'virtual-tour'
    }
  },
  {
    path: '/e-tools',
    name: 'e-tools',
    component: ETools,
    meta: {
      title: 'Explore our E-Tools',
      link: 'E-Tools'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About PLI',
      link: 'about',
      text: AboutText
    }
  }
  // {
  //   path: '/contact-us',
  //   name: 'contact',
  //   component: Contact,
  //   meta: {
  //     title: 'What can we do for you?'
  //   }
  // }
]

export default routes
