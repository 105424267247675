<template>
  <td>
    <span v-if="name === 'total'"/>
    <span v-else-if="isTotalRow">{{ formatFinalValue }}</span>
    <span v-else :class="{ negative: finalFigures[index] < 0 }">
      {{ formatValue }}
    </span>
  </td>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import _round from 'lodash/round'

export default {
  props: ['index', 'name'],
  computed: {
    ...mapState('lubeAnalyser', ['finalFigures', 'totalRowIndex']),
    ...mapGetters('lubeAnalyser', ['GET_ECV', 'GET_FINAL_TOTAL']),
    formatFinalValue () {
      if (this.GET_FINAL_TOTAL) return this.GET_ECV.toLocaleString() + ' units'
      else return ''
    },
    formatValue () {
      if (this.finalFigures[this.index]) {
        if (this.finalFigures[this.index] === '0') return '-'
        else {
          if (this.name === 'Grease') return _round((this.finalFigures[this.index] * 1000), 2).toLocaleString().replace('-', '+ ') + ' kg'
          else if (this.name === 'total') return _round((this.finalFigures[this.index] * 1000), 2).toLocaleString().replace('-', '+ ') + ' units'
          else return _round(this.finalFigures[this.index], 2).toLocaleString().replace('-', '+ ') + ' kl'
        }
      }
      else return ''
    },
    isTotalRow () {
      if (this.index === this.totalRowIndex) return true
      else return false
    }
  }
}
</script>
