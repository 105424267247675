<template>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed" activated>
    <ion-fab-button color="primary">
      <ion-icon name="md-arrow-up"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top" color="lightgreen">
      <!-- <ion-fab-button @click="goToLink('contact')" :color="isPrimary('contact')"><ion-icon src="/assets/images/menu/contact-icn.svg"/></ion-fab-button> -->
      <ion-fab-button @click="shareApp" :color="isPrimary('services')" ><ion-icon src="/assets/images/menu/share-icn.svg"/></ion-fab-button>
      <!-- <ion-fab-button @click="openBrowser" :color="isPrimary('about')"><ion-icon src="/assets/images/menu/aboutus-icn.svg"/></ion-fab-button> -->
      <ion-fab-button @click="goToLink('about')" :color="isPrimary('about')"><ion-icon src="/assets/images/menu/aboutus-icn.svg"/></ion-fab-button>
      <ion-fab-button @click="goToLink('knowhow')" :color="isPrimary('knowhow')"><ion-icon src="/assets/images/menu/academy-icn.svg"/></ion-fab-button>
      <ion-fab-button @click="goToLink('products')" :color="isPrimary('products')"><ion-icon src="/assets/images/menu/products-icn.svg"/></ion-fab-button>
      <ion-fab-button @click="goToLink('virtualtour')" :color="isPrimary('virtualtour')" class="ion-hide-md-down"><ion-icon src="/assets/images/menu/plant-icn.svg"/></ion-fab-button>
      <ion-fab-button @click="goToLink('e-tools')" :color="isPrimary('e-tools')"><ion-icon src="/assets/images/menu/e-tools-icn.svg"/></ion-fab-button>
      <ion-fab-button @click="goToLink('home')" :color="isPrimary('home')"><ion-icon src="/assets/images/menu/home-icn.svg"/></ion-fab-button>
    </ion-fab-list>
    <ion-fab-list side="end" color="lightgreen">
    </ion-fab-list>
  </ion-fab>
</template>

<script>
import { Plugins } from '@capacitor/core'
const { Share } = Plugins
const { Browser, Storage } = Plugins

export default {
  name: 'PliMenu',
  data () {
    return {
      info: {},
      appShare: {
        title: 'The PETRONAS Lubricants Toolbox App',
        text: 'Get the PETRONAS Lubricants Toolbox App',
        url: 'https://www.pli-petronas.com/en/industrial-lubricants',
        dialogTitle: 'Share the PETRONAS Lubricants Toolbox App'
      }
    }
  },
  methods: {
    goToLink(name) {
      if (this.$route.name !== name ) {
        this.set(name, name)
        this.$router.push({ name: name })
      }
      this.$ionic.menuController.close()
    },
    isPrimary(name) {
      if (this.$route.name.startsWith(name)) return 'lightgreen'
      else return 'menu'
    },
    async openBrowser() {
      await Browser.open({ url: "https://www.pli-petronas.com/en" });
    },
    async shareApp () {
      let shareRet = await Share.share(this.appShare)
      return shareRet
    },
    async set (key, value) {
      await Storage.set({
        key: key,
        value: JSON.stringify(value)
      })
    },
    async get (key) {
      const item = await Storage.get({ key: key });
      return JSON.parse(item.value);
    },
    async remove (key) {
      await Storage.remove({
        key: key
      })
    }
  }
}
</script>

<style lang="scss">
ion-menu {
  .button-clear {
    --background: var(--ion-color-lightgreen) !important;
    --background-activated: var(--ion-color-lightgreen-shade);
  }
  .button-solid {
    --box-shadow: none;
    margin: 0;
    ion-icon {
      padding: 8px;
    }
  }
  ion-button {
    width: 100%;
    text-align: left;
    float: left;
  }
}
</style>