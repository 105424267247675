<template>
  <ion-col size-lg="4" size-sm="6" size="12" class="ion-margin-bottom">
    <ion-card color="secondary" class="image-back dark-image" @click="nextStep()">
      <div class="back-image" :style="{ 'background': 'linear-gradient(to bottom, rgba(84, 84, 84, 0.2), rgba(0, 0, 0, 0.5)), url(' + getImageUrl + ') center center / cover no-repeat', 'background-size': 'cover' }"></div>
      <ion-item lines="none"/>
      <ion-item lines="none">
        <ion-card-title>{{ data.title }}</ion-card-title>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"></ion-icon>
      </ion-item>
    </ion-card>
  </ion-col>
</template>

<script>

export default {
  props: ['data'],
  computed: {
    getImageUrl() {
      let newString = this.data.industry.replace(/[^A-Z0-9]+/ig, '')
      return '/assets/images/products/' + newString + '.jpg'
    }
  },
  methods: {
    nextStep () {
      this.$router.push({name: 'e-tools.cross_sell_calculator.size', params: { industryId: this.data.id, from: this.$route.name }})
    }
  }
}
</script>