import Products from '../views/Products.vue'
import ByProduct from '../components/products/ByProduct.vue'
import ByIndustry from '../components/products/ByIndustry.vue'
import ProductByProduct from '../components/products/ProductByProduct.vue'
import ProductMain from '../components/products/ProductMain.vue'
import ProductComparison from '../components/products/ProductComparison.vue'
import OEMApprovals from '../components/approvals/OEMApprovals.vue'
import ProofOfPerformances from '../components/performance/ProofOfPerformances.vue'

const routes = [
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      title: 'Explore our products',
      link: 'Product categories'
    }
  },
  {
    path: '/products/by-product',
    name: 'products.by_product',
    component: ByProduct,
    meta: {
      title: 'Product types',
      parent: '/products'
    }
  },
  {
    path: '/products/by-industry',
    name: 'products.by_industry',
    component: ByIndustry,
    meta: {
      title: 'Industry types',
      parent: '/products'
    }
  },
  {
    path: '/products/by-product/:productTypeId',
    name: 'products.by_product.products-types',
    component: ProductByProduct,
    meta: {
      parent: '/products/by-product-types',
      title: 'Product types'
    }
  },
  {
    path: '/products/by-industry/:industryId',
    name: 'products.by_industry.industry-type',
    component: ByProduct,
    meta: {
      parent: '/products/by-industry',
      title: 'Product types'
    }
  },
  {
    path: '/products/by-industry/:industryId/:productTypeId',
    name: 'products.by_industry.industry-type.product-types',
    component: ProductByProduct,
    meta: {
      parent: '/products/by-industry/:industryId',
      title: 'Industry Product types'
    }
  },
  {
    path: '/products/comparison',
    name: 'products.comparison',
    component: ProductComparison
  },
  {
    path: '/products/product/:productId',
    name: 'products.product',
    component: ProductMain,
    meta: {
      title: 'Petronas Product Page'
    }
  },
  {
    path: '/products/approvals',
    name: 'products.approvals',
    component: OEMApprovals,
    meta: {
      title: 'OEM Approvals'
    }
  },
  {
    path: '/products/performance',
    name: 'products.performance',
    component: ProofOfPerformances,
    meta: {
      title: 'Proof of Performances'
    }
  }
]

export default routes
