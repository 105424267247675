<template>
  <ion-app id="app">
    <pli-menu/>
    <ion-vue-router />
  </ion-app>
</template> 

<script>
import PliMenu from "./components/Menu.vue"
import { mapActions, mapGetters } from 'vuex'
import { getDevice, networkStatus } from './mixins/network'
import { get, set} from './mixins/storage'
import api from './api/product'
import _isObject from 'lodash/isObject'
import { Plugins } from '@capacitor/core'
const { SplashScreen, PushNotifications } = Plugins
import { FCM } from 'capacitor-fcm'
const fcm = new FCM()

export default {
  data () {
    return {
      app_content: null,
      current_content: null,
      connected: false,
      device: {},
      os: ''
    }
  },
  components: {
    PliMenu
  },
  created () {
    this.getNetworkStatus()
    this.getCurrentDevice()
    SplashScreen.hide()
  },
  mounted () {
  },
  computed: {
    ...mapGetters('content', [ 'GET_CONTENT' ]),
  },
  methods: {
    ...mapActions('products', [ 'getProductList', 'deleteProductList', 'getLocalProducts' ]),
    ...mapActions('content', [ 'getContent', 'setContent', 'getOemApprovals', 'getPerformances', 'getCOGP', 'getOBP', 'deletePerformances', 'deleteOemApprovals', 'deleteOBP', 'deleteCOGP' ]),
    async getNetworkStatus() {
      const status = await networkStatus()
      this.connected = status.connected
      if (status.connected) {
        this.pushInit()
        this.getNewContent()
        console.log('CONNECTED', status)
      } else {
        console.log('NOT CONNECTED', status)
      }
    },
    async pushInit() {
      try {
         // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermission().then( result => {
          if (result.granted) {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register()
          } else {
            // Show some error
          }
        })
        PushNotifications.addListener('registrationError', (error) => {
          console.log(`error on register ${JSON.stringify(error)}`)
        })
        PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log(`notification ${JSON.stringify(notification)}`)
        })
        PushNotifications.addListener('pushNotificationActionPerformed',
        async (notification) => {
          console.log(`notification2 ${JSON.stringify(notification)}`)
        })
        const fcmToken = await fcm.getToken()
        console.log(fcmToken)
      } catch (e) {
        console.log(e);
      }
    },
    getAllContent() {
      this.getCOGP()
      this.getOBP()
      this.getOemApprovals()
      this.getPerformances()
      this.getProductList()
    },
    checkForNewContent() {
      // console.log('app_content', this.app_content)
      // console.log('current_content', this.current_content)
      if (_isObject(this.app_content) && _isObject(this.current_content)) {
        // console.log('current_content', this.current_content)
        let x = 0
        if (this.app_content['app_codes_of_good_practice'] > this.current_content['app_codes_of_good_practice']) {
          this.deleteCOGP()
          this.getCOGP()
          console.log('getCOGP')
          x++
        }
        if (this.app_content['app_other_best_practice'] > this.current_content['app_other_best_practice']) {
          this.deleteOBP()
          this.getOBP()
          console.log('getOBP')
          x++
        }
        if (this.app_content['app_oem_approvals'] > this.current_content['app_oem_approvals']) {
          this.deleteOemApprovals()
          this.getOemApprovals()
          console.log('getOemApprovals')
          x++
        }
        if (this.app_content['app_proof_of_performances'] > this.current_content['app_proof_of_performances']) {
          this.deletePerformances()
          this.getPerformances()
          console.log('getPerformances')
          x++
        }
        if (this.app_content['app_product_list'] > this.current_content['app_product_list']) {
          this.deleteProductList()
          this.getProductList()
          console.log('getProductList')
          x++
        } else {
          this.getLocalProducts()
        }
        if (x > 0) {
          set('APP_CONTENT', this.app_content)
          console.log('X', x)
        }
      }
    },
    async getNewContent () {
      await api.getContent((success, response) => {
        if (success) {
          // commit('SET_APP_CONTENT', response.app_content)
          // set('APP_CONTENT', response.app_content)
          this.app_content = response.app_content
          this.getCurrentContent()
          // set('APP_CONTENT', this.app_content)

        }
      })
    },
    async getCurrentContent () {
      this.current_content = await get('APP_CONTENT')
      if (!this.current_content) {
        this.getAllContent()
        set('APP_CONTENT', this.app_content)
      } else {
        this.checkForNewContent()
      }
    },
    async getCurrentDevice () {
      this.device = await getDevice()
      this.os = this.device.operatingSystem
      console.log('device', this.device)
    }
  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'museo-sans';
    src: url('/assets/fonts/MuseoSans-300.woff2') format('woff2'),
        url('/assets/fonts/MuseoSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'museo-sans';
    src: url('/assets/fonts/MuseoSans-300Italic.woff2') format('woff2'),
        url('/assets/fonts/MuseoSans-300Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'museo-sans';
    src: url('/assets/fonts/MuseoSans-500.woff2') format('woff2'),
        url('/assets/fonts/MuseoSans-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'museo-sans';
    src: url('/assets/fonts/MuseoSans-700.woff2') format('woff2'),
        url('/assets/fonts/MuseoSans-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'museo-sans';
    src: url('/assets/fonts/MuseoSans-900.woff2') format('woff2'),
        url('/assets/fonts/MuseoSans-900.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

:root {
  --ion-color-primary: #00a19c;
  --ion-color-primary-rgb: 0, 161, 156;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #008e89;
  --ion-color-primary-tint: #1aaaa6;

  --ion-color-secondary: #006e69;
  --ion-color-secondary-rgb: 0, 110, 105;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #00615c;
  --ion-color-secondary-tint: #1a7d78;

  --ion-color-tertiary: #a4a7aa;
  --ion-color-tertiary-rgb: 164, 167, 170;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #909396;
  --ion-color-tertiary-tint: #adb0b3;

  --ion-color-success: #fbb040;
  --ion-color-success-rgb: 251, 176, 64;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #dd9b38;
  --ion-color-success-tint: #fbb853;

  --ion-color-warning: #c49f05;
  --ion-color-warning-rgb: 196, 159, 5;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ac8c04;
  --ion-color-warning-tint: #caa91e;

  --ion-color-danger: #d8272c;
  --ion-color-danger-rgb: 216, 39, 44;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #be2227;
  --ion-color-danger-tint: #dc3d41;

  --ion-color-dark: #545559;
  --ion-color-dark-rgb: 84,85,89;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #4a4b4e;
  --ion-color-dark-tint: #65666a;

  --ion-color-medium: #dcdcdc;
  --ion-color-medium-rgb: 220, 220, 220;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #c2c2c2;
  --ion-color-medium-tint: #e0e0e0;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-standard: #006D68;
  --ion-color-standard-rgb: 0,109,104;
  --ion-color-standard-contrast: #ffffff;
  --ion-color-standard-contrast-rgb: 255,255,255;
  --ion-color-standard-shade: #00605c;
  --ion-color-standard-tint: #1a7c77;

  --ion-color-premium: #a4a7aa;
  --ion-color-premium-rgb: 164, 167, 170;
  --ion-color-premium-contrast: #ffffff;
  --ion-color-premium-contrast-rgb: 255, 255, 255;
  --ion-color-premium-shade: #909396;
  --ion-color-premium-tint: #adb0b3;

  --ion-color-supreme: #C49F05;
  --ion-color-supreme-rgb: 196,159,5;
  --ion-color-supreme-contrast: #ffffff;
  --ion-color-supreme-contrast-rgb: 255, 255, 255;
  --ion-color-supreme-shade: #ac8c04;
  --ion-color-supreme-tint: #caa91e;

  --ion-color-neutral: #F6F6F6;
  --ion-color-neutral-rgb: 246,246,246;
  --ion-color-neutral-contrast: #000000;
  --ion-color-neutral-contrast-rgb: 0,0,0;
  --ion-color-neutral-shade: #d8d8d8;
  --ion-color-neutral-tint: #f7f7f7;

  --ion-color-lightgreen: #caf2ee;
  --ion-color-lightgreen-rgb: 202,242,238;
  --ion-color-lightgreen-contrast: var(--ion-color-primary);
  --ion-color-lightgreen-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-lightgreen-shade: #b2d5d1;
  --ion-color-lightgreen-tint: #cff3f0;

  --ion-color-white: #fff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: var(--ion-color-primary);
  --ion-color-white-contrast-rgb: var(--ion-color-primary-rgb);
  --ion-color-white-shade: var(--ion-color-lightgreen);
  --ion-color-white-tint: #ffffff;

  --ion-color-grey: #eeeeee;
  --ion-color-grey-rgb: 238,238,238;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0,0,0;
  --ion-color-grey-shade: #d1d1d1;
  --ion-color-grey-tint: #f0f0f0;

  --ion-font-family:'museo-sans', sans-serif;
  --ion-text-color: #3D3935;
  --ion-grid-padding: 20px;
  --ion-grid-column-padding: 0;
  --ion-grid-padding-md: 10px;
}

.ion-color-premium {
  --ion-color-base: var(--ion-color-premium);
  --ion-color-base-rgb: var(--ion-color-premium-rgb);
  --ion-color-contrast: var(--ion-color-premium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-premium-contrast-rgb);
  --ion-color-shade: var(--ion-color-premium-shade);
  --ion-color-tint: var(--ion-color-premium-tint);
}

.ion-color-standard {
  --ion-color-base: var(--ion-color-standard);
  --ion-color-base-rgb: var(--ion-color-standard-rgb);
  --ion-color-contrast: var(--ion-color-standard-contrast);
  --ion-color-contrast-rgb: var(--ion-color-standard-contrast-rgb);
  --ion-color-shade: var(--ion-color-standard-shade);
  --ion-color-tint: var(--ion-color-standard-tint);
}

.ion-color-supreme {
  --ion-color-base: var(--ion-color-supreme);
  --ion-color-base-rgb: var(--ion-color-supreme-rgb);
  --ion-color-contrast: var(--ion-color-supreme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-supreme-contrast-rgb);
  --ion-color-shade: var(--ion-color-supreme-shade);
  --ion-color-tint: var(--ion-color-supreme-tint);
}

.ion-color-neutral {
  --ion-color-base: var(--ion-color-neutral);
  --ion-color-base-rgb: var(--ion-color-neutral-rgb);
  --ion-color-contrast: var(--ion-color-neutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-neutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-neutral-shade);
  --ion-color-tint: var(--ion-color-neutral-tint);
}

.ion-color-lightgreen {
  --ion-color-base: var(--ion-color-lightgreen);
  --ion-color-base-rgb: var(--ion-color-lightgreen-rgb);
  --ion-color-contrast: var(--ion-color-lightgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgreen-shade);
  --ion-color-tint: var(--ion-color-lightgreen-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-menu {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-neutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-neutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.table-wrapper {
  td:nth-last-child(1) {
    background-color: var(--ion-color-grey)
  }
}

.picked {
  background-color: var(--ion-color-lightgreen);
  ion-item .item-native {
    background-color: var(--ion-color-lightgreen);
  }
  td:nth-last-child(1) {
    background-color: var(--ion-color-grey-shade)
  }
}

ion-toolbar {
  &.supreme {
    --background: #C49F05;
    --color: #FFF;
  }
  &.premium {
    --background: #A4A7AA;
    --color: #FFF;
  }
  &.standard {
    --background: #006D68;
    --color: #FFF;
  }
  &.neutral {
    --background: #f6f6f6;
    --color: #222;
  }
}
.ion-page h1 {
  font-weight: 900;
  font-size: 200%;
}
ion-card {
  ion-card-title {
  font-weight: 900;
  font-size: 24px;
    &.h1 {
      font-weight: 800;
      font-size: 180%;
    }
    &.h2 {
      font-size: 160%;
      margin-bottom: 10px;
    }
  }
}

</style>