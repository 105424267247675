<template>
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-title v-html="title"></ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon slot="icon-only" name="close"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-text v-html="content"/>
    </ion-content>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String
  },
  data() {
    return {
      content: 'Content'
    }
  }
}
</script>