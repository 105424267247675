<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="sendBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Product Types</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
          <ion-row>
            <ion-col size-md="8" size="12" class="ion-padding">
              <h1 class="title">{{ pageTitle() }}</h1>
            </ion-col>
          </ion-row>
          <ion-toolbar>
            <ion-buttons slot="end">
              <ion-button
                v-if="!tick && products.length > 1"
                color="primary"
                fill="outline"
                @click="compareProducts()"
              >Compare
              </ion-button>
              <ion-button
                slot="end"
                color="primary"
                fill="outline"
                @click="productsComparison()"
                v-if="choices.length > 0"
                :disabled="choices.length < 2 || choices.length > 3"
              >Compare {{ choices.length }}
              </ion-button>
              <ion-button color="dark" fill="outline" v-show="tick" @click="cancelCompare()">cancel</ion-button>
              <ion-button color="success" @click="presentAlert()" v-if="products.length > 1">
                <ion-icon slot="icon-only" name="information-circle-outline"/>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        <div v-if="!term.filtering">
          <ion-row>
             <product-block
              v-for="(product, index) in term.products"
              :key="index"
              :tick="tick"
              :data="product"
              @clicked="onClickChild"
              :cancel="cancel"
            />
          </ion-row>
        </div>
        <template v-else>
          <product-section v-for="(section, key, index) in filtered" :key="key" :section="section" :sectionOrder="index" :activeItem="active" :sectionId="key" @expanded-item="expandedChild"/>
        </template>
      </ion-grid>
      <ion-grid v-if="eTools[id]" fixed>
        <ion-row>
          <image-card
            col="4"
            title="Industrial Lube Selector"
            description="Choose the right oil for you"
            imageName="lubeselector.png"
            :link="eTools[id].route"
          />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import ProductSection from "./ProductSection.vue"
import ProductBlock from "./blocks/ProductBlock.vue"
import ImageCard from "./../blocks/ImageCard.vue"
import { mapGetters, mapActions } from 'vuex'
import route from './../../mixins/route'

export default {
  mixins: [route],
  components: {
    ProductSection,
    ProductBlock,
    ImageCard
  },
  data() {
    return {
      active: 0,
      industries: null,
      products: {},
      title: this.$route.meta.title,
      id: this.$route.params.productTypeId || this.$route.params.productId,
      tick: false,
      choices: [],
      cancel: true,
      filtered: null,
      term: {},
      i: 0,
      eTools: {
        118: { title: "Circulating Oil", route: "e-tools.lube_tools.circulating_oil" },
        123: { title: "Air Compressor Oil", route: "e-tools.lube_tools.air_compressor_oil" },
        125: { title: "Gear Oil", route: "e-tools.lube_tools.gear_oil" },
        122: { title: "Hydraulic Oil", route: "e-tools.lube_tools.hydraulic_oil" },
        124: { title: "Turbine Oil", route: "e-tools.lube_tools.turbine_oil" }
      }
    }
  },
  computed: {
    ...mapGetters('products', [ 'GET_CURRENT_CATEGORY', 'GET_CURRENT_INDUSTRY'  ])
  },
  mounted() {
    this.setCurrentCategory(this.id)
    this.fetchProducts()
  },
  methods: {
    ...mapActions('products', [ 'setCurrentCategory' ]),
    expandedChild (value) {
      this.active = value
    },
    presentAlert() {
      return this.$ionic.alertController
        .create({
          header: 'Compare',
          subHeader: 'Up to 3 Products',
          message: '<p>Click the \'Compare\' button to compare products within their product category.</p><p> You can compare up to 3 products</p>',
          buttons: ['OK'],
        })
        .then(a => a.present())
    },
    fetchProducts() {
      this.term = this.GET_CURRENT_CATEGORY
      if (!this.$route.params.industryId) {
        this.term.filtering ? this.filtered = this.term.sections : this.products = this.term.products
        this.title = this.term.name
      } else {
        this.term.filtering ? this.filtered = this.GET_CURRENT_INDUSTRY.categories[this.id].sections : this.products = this.GET_CURRENT_INDUSTRY.categories[this.$route.params.productTypeId].products
        this.title = this.term.name + ' for ' + this.GET_CURRENT_INDUSTRY.name
      }
    },
    pageTitle() {
      return this.title
    },
    compareProducts() {
      this.tick = !this.tick
      this.cancel = false
    },
    cancelCompare() {
      this.compareProducts()
      this.cancel = true
      this.choices = []
    },
    productsComparison() {
      this.$router.push({
        name: "products.comparison",
        query: {
          nid: this.choices.join(","),
          category: this.$route.params.productTypeId,
          industry: this.$route.params.industryId
        },
        params: {
          from: this.$route
        }
      })
    },
    onClickChild(value) {
      if (this.choices.includes(value)) {
        this.choices = this.choices.filter(choice => choice !== value)
      } else {
        this.choices.push(value)
      }
    }
  }
}
</script>