<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="sendBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Change Plantsize</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content class="cross-sell-wrapper ion-padding-bottom ion-margin-bottom">
      <ion-grid fixed>
        <!-- <ion-row>
          <ion-item color="white">
            <ion-buttons slot="start">
              <span v-for="(crumb, i) in crumbs" :key="i">
                <ion-button v-if="i != 0" disabled size="small">/</ion-button>
                <ion-button @click="sendBackwards(i)" style="text-transform:none;letter-spacing:normal">
                  {{ crumb.title }}
                </ion-button>
              </span>
            </ion-buttons>
          </ion-item>
        </ion-row> -->
        <ion-row>
          <ion-col class="ion-padding">
            <h1 class="title">Discover your opportunities<br></h1>
            <ion-text color="dark">Industry: {{ industrydata.title }}, {{ plantSizeText }}</ion-text>
          </ion-col>
        </ion-row>
        <!-- <ion-row>
          <ion-col class="ion-text-center ion-no-padding">
            <ion-title color="primary">Step 3</ion-title>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center ion-padding-vertical" color="white">
            <template v-for="(radio, i) in ['on', 'on', 'on']">
              <ion-button fill="clear" size="small" color="primary" v-if="radio === 'on'" :key="i"><ion-icon size="small" slot="icon-only" name="radio-button-on"/></ion-button>
              <ion-button fill="clear" size="small" color="primary" v-else :key="i" disabled><ion-icon size="small" slot="icon-only" name="radio-button-off"/></ion-button>
            </template>
          </ion-col>
        </ion-row> -->
      </ion-grid>
      <ion-grid class="ion-no-padding ion-padding-bottom ion-margin-bottom" fixed>
        <ion-row class="wrapper">
          <Table/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import route from './../../mixins/route'
import Table from "./TableNew.vue"

export default {
  mixins: [route],
  components: { Table },
  data() {
    return {
      mainValue: '',
      crumbs: [
        {
          title: 'Choose Industry',
          link: 'e-tools.cross_sell_calculator'
        },
        {
          title: 'Change Plant size',
          link: 'e-tools.cross_sell_calculator.size.calculation'

        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.sizeId === 4) this.SET_DONT_KNOW()
    this.setPlantSize(this.$route.params.sizeId)
    this.setMergedData(this.$route.params.sizeId)
  },
  computed: {
    ...mapState("lubeAnalyser", ['plantSizes', 'industrydata', 'sizeRange', 'energyType']),
    plantSizeText () {
      return 'Plant size: ' + this.plantSizes[this.$route.params.sizeId - 1].name + ' ' + this.plantSizeOutput()
    }
  },
  methods: {
    ...mapMutations('lubeAnalyser', [ 'SET_DONT_KNOW' ]),
    ...mapActions("lubeAnalyser", ['setPlantSize', 'resetStates', 'setMergedData']),
    plantSizeOutput () {
      const index = this.$route.params.sizeId - 1
      if (this.sizeRange[index]) return '(' + this.sizeRange[index] + ' ' + this.energyType + ')'
      else return ''
    },
    sendBackwards (num) {
      const routeArr = this.$route.name.split('.')
      routeArr.splice(num + 2)
      // eslint-disable-next-line
      console.warn('routeArr', routeArr)
      const routeStr = routeArr.join('.')
      this.$router.push({ name: routeStr })
    }
  },
  beforeDestroy() {
    this.resetStates()
  }
}
</script>

<style lang="scss">
$darkRed: #d8272c;

.cross-sell-wrapper {
  .table-wrapper {
    width: 100%;
    table {
      width: 100%;
    }
    th {
      padding: 10px;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 12px;
      text-align: left;

      &:nth-child(1) {
        width: 6%;
      }
    }
    td {
      padding: 10px;
      vertical-align: middle;
      .input-container {
        margin-top: 0;
      }
      &:nth-last-child(1) {
        font-weight: 800;
      }
      &:nth-child(1) {
        font-weight: 800;
      }
      .negative {
        color: $darkRed;
      }
    }
    tr:nth-last-child(1) {
      td:nth-last-child(1) {
        border-right: none;
        background: transparent;
      }
    }
    tr {
      border-bottom: 1px solid silver;
      &:nth-last-child(1) {
        border: none;
      }
      &:nth-last-child(2) {
        border-bottom: 3px solid silver;
      }
    }
    tr.table-header {
      border-bottom: 3px solid silver;
    }
  }
}
</style>

