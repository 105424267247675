<template>
  <ion-list>
    <ion-list-header color="light">
      <ion-label>{{ title }}</ion-label>
    </ion-list-header>
    <ion-item :color="item.performance.toLowerCase()" v-for="item in list" :key="item.id" @click="addNewProduct(item.id)" lines="full">
      <ion-label v-if="item.name">{{ item.name }}</ion-label>
      <ion-label v-else-if="item.title_short">{{ item.title_short }}</ion-label>
      <ion-label v-else-if="item.title">{{ item.title }}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  name: 'Popver',
  props: [ 'list', 'title' ],
  methods: {
    async addNewProduct(id) {
      await this.$ionic.popoverController.dismiss(id)
    }
  }
}
</script>