<template>
  <ion-col size-lg size-sm="6" size="12">
    <ion-card class="block-icon ion-activatable" @click="click(data)" color="grey">
      <ion-card-content/>
      <ion-item lines="none">
        <ion-subtitle>{{ data.title }}</ion-subtitle>
        <ion-subtitle v-if="data.subtitle">{{ data.subtitle }}</ion-subtitle>
        <ion-icon color="primary" name="md-arrow-forward" slot="end"/>
      </ion-item>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
export default {
  props: ['data', 'index', 'click']
}
</script>
