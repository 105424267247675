<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Explore our products</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" size="12" class="ion-padding">
            <h1 class="title">Industry types</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ProductTypeBlock v-for="(product,i) in sortFunc" :data="product" :key="i"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import ProductTypeBlock from './blocks/ProductTypeBlock.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ProductTypeBlock
  },
  computed: {
    ...mapGetters('products', [ 'GET_INDUSTRIES' ]),
    sortFunc () {
      let sorted = Object.values(this.GET_INDUSTRIES)
      return _orderBy(sorted, 'name', 'asc' )
    }
  },
  methods: {
    goBack() {
      const name = this.$route.name.split('.', 1).toString()
      this.$router.push({
        name: name
      })
    }
  }
}
</script>