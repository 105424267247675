<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Explore our products</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">Product names</ion-label>
              <ion-select interface="action-sheet" :value="productOption" @ionChange="changeProduct($event.target.value)" :disabled="disabled.productList">
                <ion-select-option v-for="item in popProducts" :value="item.id" :key="item.id">{{ 'PETRONAS ' + item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">Product categories</ion-label>
              <ion-select interface="action-sheet" :value="categoryOption" @ionChange="changeCategory($event.target.value)" :disabled="disabled.categoryList">
                <ion-select-option v-for="item in popCategories" :value="item.id" :key="item.id">{{ item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">Industry</ion-label>
              <ion-select interface="action-sheet" :value="industryOption" @ionChange="changeIndustry($event.target.value)" :disabled="disabled.industryList">
                <ion-select-option v-for="item in popIndustries" :value="item.id" :key="item.id">{{ item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-button @click="resetForm()" expand="block">
              Reset
              <ion-icon
                slot="end"
                name="refresh"
              />
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ProofOfPerformancesBlock 
            v-for="pop in items" 
            :data="pop" 
            :image="getImage(pop.company)" 
            :company="getCompanyName(pop.company)" 
            :key="pop.id" 
            :online="online"
          />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// import _filter from 'lodash/filter'
import ProofOfPerformancesBlock from './ProofOfPerformancesBlock.vue'
import { get } from "../../mixins/storage"
import { networkStatus } from '../../mixins/network'

export default {
  components: {
    ProofOfPerformancesBlock
  },
  data () {
    return {
      online: false,
      disabled: {
        productList: false,
        categoryList: false,
        industryList: false
      },
      pops: [],
      pop_companies: {},
      filters: {
        pop_products: [],
        pop_categories: [],
        pop_industries: []
      },
      text: 'Explore our proof of performances directory.',
      // companyOption: null,
      categoryOption: null,
      productOption: null,
      industryOption: null
    }
  },
  mounted () {
    this.getApprovals()
    this.deviceOnline()
  },
  computed: {
    items () {
      let items = this.pops
      return items
    },
    popProducts () {
      let products = this.filters.pop_products
      return products.sort(this.compareValues('title'))
    },
    popCategories () {
      let categories = this.filters.pop_categories
      return categories.sort(this.compareValues('title'))
    },
    popIndustries () {
      let industries = this.filters.pop_industries
      return industries.sort(this.compareValues('title'))
    }
  },
  methods: {
    async deviceOnline() {
      const network = await networkStatus()
      this.online = network.connected
    },
    goBack() {
      const name = this.$route.name.split('.', 1).toString()
      this.$router.push({
        name: name
      })
    },
    compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    async getApprovals() {
      this.pop_companies = await get('APP_PERFORMANCES_COMPANIES')
      this.pops = await get('APP_PROOF_OF_PERFORMANCES')
      this.filters.pop_products = await get('APP_PERFORMANCES_PRODUCTS')
      this.filters.pop_categories = await get('APP_PERFORMANCES_CATEGORIES')
      this.filters.pop_industries = await get('APP_PERFORMANCES_INDUSTRIES')
    },
    getImage(id) {
      return this.pop_companies[id].image
    },
    getCompanyName(id) {
      return this.pop_companies[id].title
    },
    resetForm() {
      this.getApprovals()
      // this.companyOption = null
      this.categoryOption = null
      this.productOption = null
      this.industryOption = null
    },
    changeIndustry(value) {
      this.disabled.industryList = !this.disabled.industryList
      if(value) {
        this.industryOption = value
        this.pops = this.pops.filter(pop => pop.industries[value])

        const products = this.pops.map(product => Object.keys(product.products)).flat()
        // console.log('products', products)
        this.filters.pop_products = this.filters.pop_products.filter(product => products.includes(product.id))

        const categories = this.pops.map(category => Object.keys(category.categories)).flat()
        // console.log('categories', categories)
        this.filters.pop_categories = this.filters.pop_categories.filter(category => categories.includes(category.id))
      }
    },
    changeProduct(value) {
      this.disabled.productList = !this.disabled.productList
      if(value) {
        this.productOption = value
        this.pops = this.pops.filter(pop => pop.products[value])

        const categories = this.pops.map(category => Object.keys(category.categories)).flat()
        // console.log('categories', categories)
        this.filters.pop_categories = this.filters.pop_categories.filter(category => categories.includes(category.id))

        const industries = this.pops.map(industry => Object.keys(industry.industries)).flat()
        // console.log('industries', industries)
        this.filters.pop_industries = this.filters.pop_industries.filter(industry => industries.includes(industry.id))
      }
    },
    changeCategory(value) {
      this.disabled.categoryList = !this.disabled.categoryList
      if(value) {
        this.categoryOption = value
        this.pops = this.pops.filter(pop => pop.categories[value])

        const products = this.pops.map(product => Object.keys(product.products)).flat()
        // console.log('products', products)
        this.filters.pop_products = this.filters.pop_products.filter(product => products.includes(product.id))
        
        const industries = this.pops.map(industry => Object.keys(industry.industries)).flat()
        // console.log('industries', industries)
        this.filters.pop_industries = this.filters.pop_industries.filter(industry => industries.includes(industry.id))
      }
    }
  }
}
</script>