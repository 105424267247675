<template>
  <tr class="row" :class="chosenRow" :style="subtractRow">
    <!-- <td>
      <RadioButton
        :name="row.name"
        :index="index"
      />
    </td> -->
    <td @click="makePrimary">
      <ion-button slot="start" color="primary" fill="clear" v-if="row.name !== GET_PICKED.name">
        <ion-icon slot="start" name="radio-button-off"/>
        <ion-label v-if="row.name !== 'total'" >{{ row.name }}</ion-label>
        <ion-label v-else>TOTAL CONSUMPTION</ion-label>
      </ion-button>
      <ion-button slot="start" color="secondary" fill="clear" v-else>
        <ion-icon slot="start" name="radio-button-on"/>
        <ion-label v-if="row.name !== 'total'" >{{ row.name }}</ion-label>
        <ion-label v-else>TOTAL CONSUMPTION</ion-label>
      </ion-button>
    </td>
    <AverageQuant
      v-if="this.$route.params.sizeId !== 4"
      :index="index"
      :name="row.name"
    />
    <td v-else>
      {{ GET_TABLE_DATA[index].percentage + '%' }}
    </td>
    <td class="units">
      <CalcInput
        :unitType="unitQuantityType(row.name)"
        :index="index"
        :name="row.name"
        :percent="row.percentage"
      />
    </td>
    <RowTotal
      :index="index"
      :name="row.name"
    />
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CalcInput from './CalcInput.vue'
import RowTotal from './RowTotal.vue'
import AverageQuant from './AverageQuant.vue'

export default {
  components: { CalcInput, RowTotal, AverageQuant },
  props: ['row', 'index'],
  data() {
    return {
      hover: false,
      styles: {
        'border-right': '2px solid #ddd'
      }
    }
  },
  computed: {
    ...mapGetters('lubeAnalyser', ['GET_PICKED', 'GET_MAIN_VALUE', 'GET_SUBTRACTION_VALUE', 'GET_TABLE_DATA']),
    chosenRow () {
      if (this.GET_PICKED.name === this.row.name) return 'picked'
      else return ''
    },
    subtractRow () {
      if (this.GET_SUBTRACTION_VALUE(this.index)) return this.styles
      else return ''
    }
  },
  methods: {
    ...mapActions('lubeAnalyser', ['setPicked', 'resetPicked', 'setNewPicked']),
    unitQuantityType (name) {
      if (name === 'Grease') return 'kg'
      else if (name === 'total') return 'units'
      else return 'kl'
    },
    offerChange () {},
    makePrimary () {
      if (this.GET_MAIN_VALUE.value && this.GET_SUBTRACTION_VALUE(this.index)) {
        this.resetPicked({
          name: this.row.name,
          index: this.index
        })
      } else if (this.GET_MAIN_VALUE.value) {
        this.setNewPicked({
          name: this.row.name,
          index: this.index
        })
      } else {
        this.setPicked({
          name: this.row.name,
          index: this.index
        })
      }
    }
  }
}
</script>

