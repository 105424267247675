<template>
  <ion-page>
    <top-toolbar>
      <template v-slot:left>
        <ion-button @click="goBack">
          <ion-icon slot="start" name="md-arrow-back"/>
          <ion-label>Explore our products</ion-label>
        </ion-button>
      </template>
    </top-toolbar>
    <ion-content>
      <ion-grid fixed>
        <ion-row>
          <ion-col size-md="8" class="ion-padding">
            <h1 class="title">{{ $route.meta.title }}</h1>
            <ion-text v-html="text" color="dark"/>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">Product names</ion-label>
              <ion-select interface="action-sheet" :value="productOption" @ionChange="changeProduct($event.target.value)" :disabled="disabled.productList">
                <ion-select-option v-for="item in productsList" :value="item.id" :key="item.id">{{ 'PETRONAS ' + item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">Product categories</ion-label>
              <ion-select interface="action-sheet" :value="categoryOption" @ionChange="changeCategory($event.target.value)" :disabled="disabled.categoryList">
                <ion-select-option v-for="item in categoriesList" :value="item.id" :key="item.id">{{ item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-item>
              <ion-label position="floating">OEM name</ion-label>
              <ion-select interface="action-sheet" :value="companyOption" @ionChange="changeCompany($event.target.value)" :disabled="disabled.oemList">
                <ion-select-option v-for="item in companiesList" :value="item.id" :key="item.id">{{ item.title }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size-lg="3" size-md="4" size="12" class="ion-padding">
            <ion-button @click="resetForm()" expand="block">
              Reset
              <ion-icon
                slot="end"
                name="refresh"
              />
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <OemBlock v-for="approval in items" :data="approval" :image="getImage(approval.oem_company)" :key="approval.id"/>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// import _filter from 'lodash/filter'
import OemBlock from './OemBlock.vue'
import { get } from "../../mixins/storage"

export default {
  components: {
    OemBlock
  },
  data () {
    return {
      disabled: {
        productList: false,
        categoryList: false,
        oemList: false
      },
      approvals: [],
      oem_companies: {},
      filters: {
        oem_company_array: [],
        oem_products: [],
        oem_categories: []
      },
      text: 'Explore our OEM approvals.',
      companyOption: null,
      categoryOption: null,
      productOption: null
    }
  },
  mounted () {
    this.getApprovals()
  },
  computed: {
    items () {
      let items = this.approvals
      return items
    },
    productsList () {
      let products = this.filters.oem_products
      return products.sort(this.compareValues('title'))
    },
    companiesList () {
      let companies = this.filters.oem_company_array
      return companies.sort(this.compareValues('title'))
    },
    categoriesList () {
      let categories = this.filters.oem_categories
      return categories.sort(this.compareValues('title'))
    }
  },
  methods: {
    goBack() {
      const name = this.$route.name.split('.', 1).toString()
      this.$router.push({
        name: name
      })
    },
    compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    async getApprovals() {
      this.oem_companies = await get('APP_OEM_COMPANIES')
      this.approvals = await get('APP_OEM_APPROVALS')
      this.filters.oem_company_array = await get('APP_OEM_COMPANY_ARRAY')
      this.filters.oem_products = await get('APP_OEM_PRODUCTS')
      this.filters.oem_categories = await get('APP_OEM_CATEGORIES')
    },
    getImage(id) {
      return this.oem_companies[id].image
    },
    resetForm() {
      this.getApprovals()
      this.companyOption = null
      this.categoryOption = null
      this.productOption = null
    },
    changeProduct(value) {
      this.disabled.productList = !this.disabled.productList
      if(value) {
        this.productOption = value
        this.approvals = this.approvals.filter(approval => approval.products[value])

        const categories = this.approvals.map(category => Object.keys(category.categories)).flat()
        this.filters.oem_categories = this.filters.oem_categories.filter(category => categories.includes(category.id))

        const companies = this.approvals.map(company => company.oem_company)
        this.filters.oem_company_array = this.filters.oem_company_array.filter(company => companies.includes(company.id))
      }
    },
    changeCategory(value) {
      this.disabled.categoryList = !this.disabled.categoryList
      if(value) {
        this.categoryOption = value
        this.approvals = this.approvals.filter(approval => approval.categories[value])

        const companies = this.approvals.map(company => company.oem_company)
        this.filters.oem_company_array = this.filters.oem_company_array.filter(company => companies.includes(company.id))

        const products = this.approvals.map(product => Object.keys(product.products)).flat()
        this.filters.oem_products = this.filters.oem_products.filter(product => products.includes(product.id))
      }
    },
    changeCompany(value) {
      this.disabled.oemList = !this.disabled.oemList
      if(value) {
        this.companyOption = value
        this.approvals = this.approvals.filter(approval => approval.oem_company === value)

        const categories = this.approvals.map(category => Object.keys(category.categories)).flat()
        this.filters.oem_categories = this.filters.oem_categories.filter(category => categories.includes(category.id))

        const products = this.approvals.map(product => Object.keys(product.products)).flat()
        this.filters.oem_products = this.filters.oem_products.filter(product => products.includes(product.id))
      }
    }
  }
}
</script>