<template>
  <ion-col size-lg="4" size-sm="6" size="12" class="ion-margin-bottom ion-padding-bottom">
    <ion-card color="dark" v-if="product" class="product-block ion-activatable" @click="change">
      <ion-card-header class="ion-no-padding ion-padding-top">
        <ion-item lines="none">
          <ion-buttons slot="end" v-if="tick">
            <ion-button size="large" color="white">
              <ion-icon size="large" slot="icon-only" name="checkbox" v-if="isChecked"/>
              <ion-icon size="large" slot="icon-only" name="square" v-else/>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-card-header>
      <ion-card-content class="main-content-container ion-text-center">
        <ion-item lines="none" class="ion-margin-bottom">
          <img src="/assets/images/logos/logo-flat-white.svg" style="height:60px;" class="plilogo"/>
        </ion-item>
        <ion-card-title class="h1 ion-text-uppercase">{{ product.title_short }}</ion-card-title>
        <ion-text color="light">
          <p>{{ product.oil_type }}</p>
        </ion-text>
      </ion-card-content>
      <ion-footer class="ion-margin-top">
        <ion-toolbar :color="product.performance.toLowerCase()" class="ion-text-center">
          <ion-label>Performance: {{ product.performance }}</ion-label>
        </ion-toolbar>
      </ion-footer>
      <ion-ripple-effect/>
    </ion-card>
  </ion-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['data', 'tick', 'cancel'],
  data () {
    return {
      isChecked: false,
      product: ''
    }
  },
  mounted () {
    this.product = this.GET_PRODUCT_BLOCK(this.data)
  },
  computed: {
    ...mapGetters('products', [ 'GET_PRODUCT_BLOCK' ])
  },
  methods: {
    change () {
      if (this.tick) {
        this.isChecked = !this.isChecked
        this.$emit('clicked', this.data)
      } else {
        this.$router.push({
          name: "products.product",
          params: {
            productId: this.data,
            from: this.$route
          }
        })
      }
    }
  },
  watch: {
    cancel () {
      if (this.cancel === true) {
        this.isChecked = false
      }
    }
  }
}
</script>

<style lang="scss">

.block.mini-product-block {
  display: block;
  overflow: hidden;
  min-height: 250px;
  max-height: none;
  cursor: pointer;
  height: auto;
}
.plilogo path {
  fill: red;
}
</style>
